import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss']
})
export class InvoicePrintComponent implements OnInit {
  invoice_id: any;
  individual_invoice: any;
  words_amount: any;
  invoice_amount: any;
  vat_amount: any;
  invoice_qty: any;
  ind_trip: any;
  receiver: any;
  ind_loaded_total: any;

  constructor(private tripService: TripService, route: ActivatedRoute, private printservice: PrintServiceService) {
    this.invoice_id = route.snapshot.params['invoice_id'];
    // console.log(this.invoice_id);
  }

  ngOnInit(): void {
    this.tripService.getInvoiceDetails(this.invoice_id).subscribe((res: any) => {
      // console.log(res);
      
      this.words_amount = res.words_amount;
      this.invoice_qty = res.loaded_pkg;
      // console.log(res);
      this.words_amount = res.words_amount;
      if (res.invoice[0]) {
        this.invoice_amount = res.invoice[0].invoice_amount+res.invoice[0].vat_invoice_amt;
        this.vat_amount = res.invoice[0].vat_amount;
        this.receiver = res.invoice[0];
        this.receiver.invoice_id = this.receiver.invoice_id.toString().padStart(4, "0");
      } else {
        this.invoice_amount = 0;
        this.vat_amount = 0;
        this.receiver = []
      }
      let individual_invoice = res.invoice;
      this.individual_invoice = individual_invoice.filter(element => element.is_show === 1);
      // this.loaded_total = this.individual_invoice.map(t => Number(t.loaded_pkgs)).reduce((acc, value) => acc + value, 0);
      if(this.individual_invoice.length <10){
        let fillCount = 10 - this.individual_invoice.length;
        let filledArray = new Array(fillCount).fill(undefined);
        this.individual_invoice = this.individual_invoice.concat(filledArray);
      }
      // console.log(this.individual_invoice);
      // console.log(this.ind_trip);

      var file_name = "Invoice " + this.invoice_id
      this.printservice.onDataReady(file_name,'printSection','A4');
    });

  }

}
