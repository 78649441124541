<div class="row" id="waybill-print">
    <div class="col-md-6 head">
        <h4 class="text-center"><strong> Job NO: &nbsp;{{way_bill?.waybill_no}}</strong></h4>
    </div>
    <div class="col-md-6 head">
        <h4 class="text-center"><strong> Date: &nbsp;{{way_bill?.waybill_date | date:"dd-MM-yyyy"}} </strong></h4>
    </div>
</div>
<div class="row">
    <div class="col-md-6 ">
        <h4 class="text-center"><strong> SENDER NAME AND ADDRESS </strong></h4>
        <h5 class="margin"> Name : {{way_bill?.sender_name}}</h5>
        <h5 class="margin"> Code : {{way_bill?.sender_code}}</h5>
        <h5 class="margin"> Address : {{way_bill?.sender_address}}</h5>
        <h5 class="margin"> Mobile : {{way_bill?.sender_mobile}}</h5>
        <h5 class="margin"> Email : {{way_bill?.sender_email}}</h5>
        <h5 class="margin"> Country : {{way_bill?.sender_country_name}}</h5>
    </div>
    <div class="col-md-6 ">
        <h4 class="text-center"><strong> RECEIVER NAME AND ADDRESS </strong></h4>
        <h5 class="margin"> Name : {{way_bill?.receiver_name}}</h5>
        <h5 class="margin"> Code : {{way_bill?.receiver_code}}</h5>
        <h5 class="margin"> Address : {{way_bill?.receiver_address}}</h5>
        <h5 class="margin"> Mobile : {{way_bill?.receiver_mobile}}</h5>
        <h5 class="margin"> Email : {{way_bill?.receiver_email}}</h5>
        <h5 class="margin"> Country : {{way_bill?.receiver_country_name}}</h5>
    </div>
</div>

<div class="container body-color">
    <div class="card">
        <div class="card-body">
            <div class="row head-color justify-content-center text-light pt-3 mt-2">
                <h4><b>Description of contents</b></h4>
            </div>
            <div class="row c-head ridge_border text-light">
                <div class="col-sm-1 col-md-1 col-lg-1 padding">
                    <h4><strong>No</strong></h4>
                </div>
                <div class=" col-sm-5 col-md-5 col-lg-5">
                    <h4><strong>Commodities</strong></h4>
                </div>
                <div class=" col-sm-2 col-md-2 col-lg-2">
                    <h4><strong>Package</strong></h4>
                </div>
                <div class=" col-sm-4 col-md-4 col-lg-4">
                    <h4 style="white-space: nowrap;"><strong>Origin</strong></h4>
                </div>
            </div>
            <div class="row c-body ridge_border pt-1" *ngFor="let com of commodities;index as i">
                <div class="col-sm-1 col-md-1 col-lg-1 padding">
                    {{i+1}}
                </div>
                <div class=" col-sm-5 col-md-5 col-lg-5">
                    {{com?.item_name}}
                </div>
                <div class=" col-sm-2 col-md-2 col-lg-2">
                    {{com?.quantity}} {{com.item_unit}}
                </div>
                <div class=" col-sm-4 col-md-4 col-lg-4">
                    {{com?.country_name}}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-6">
                    <h4 class="text-center"><strong> ORIGIN </strong></h4>
                    <h5 class="text-center"> {{way_bill?.origin}} </h5>
                </div>
                <div class="col-md-6">
                    <h4 class="text-center"><strong> DESTINATION </strong></h4>
                    <h5 class="text-center"> {{way_bill?.destination}} </h5>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <h5>Shipment mode:
                        {{way_bill?.shipment_mode==1 ? 'BY ROAD': way_bill?.shipment_mode==2 ? 'BY
                        SEA': way_bill?.shipment_mode==3 ? 'BY AIR' : 'Clearing'}}</h5>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-3 col-md-3 col-lg-3">
                    <h5>Measurement</h5>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    Length: {{way_bill?.measurement_l}}
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    Width: {{way_bill?.measurement_w}}
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    Height: {{way_bill?.measurement_h}}
                </div>
            </div>
            <div class="row">

                <!-- <div class="col-sm-3 col-md-3 col-lg-3">
            Volume: {{way_bill?.measurement_l*way_bill?.measurement_w*way_bill?.measurement_h}}
        </div> -->
                <div class="col-3">
                    <h5 style="white-space: nowrap;">Volume</h5>
                </div>
                <div class="col-12 col-sm-12 col-md-6 ">
                    Length X Width X Height =
                    {{way_bill?.measurement_l*way_bill?.measurement_w*way_bill?.measurement_h}}
                    CM<sup>3</sup>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    Weight: {{way_bill?.weight}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <h5>Narration: {{way_bill?.narration}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>