import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PrintServiceService } from 'app/services/print-service.service';
import { WaybillService } from 'app/services/waybill.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { TripService } from 'app/services/trip.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { UserService } from 'app/services/user.service';
import { WayBillmodalComponentComponent } from '../way-billmodal-component/way-billmodal-component.component';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { MatPaginator } from '@angular/material/paginator';

declare var $: any;
@Component({
  selector: 'app-waybill-report-component',
  templateUrl: './waybill-report-component.component.html',
  styleUrls: ['./waybill-report-component.component.scss']
})
export class WaybillReportComponentComponent implements OnInit {

  // short key
  mac = 'command+s';
  win = 'ctrl+s';
  isMac = navigator.platform.includes('Mac');
  saveCommand = this.isMac ? this.mac : this.win;
  saveCommandTitle = this.isMac ? '⌘+s' : this.win;

  mac1 = 'command+c';
  win1 = 'ctrl+c';
  isMac1 = navigator.platform.includes('Mac');
  cancelCommand = this.isMac1 ? this.mac1 : this.win1;
  cancelCommandTitle = this.isMac1 ? '⌘+c' : this.win1;

  way_bills: any;
  ind_way_bill: any;
  search = false;
  way_bills_search: any;
  way_bill_customer: any;
  way_bill_number: any;
  show_way_bill = false;
  print_or_export = false;
  way_bill: any;
  commodities: any[];
  displayedWayBills = ['index', 'waybill_date', 'waybill_no', 'sender_name', 'receiver_name', 'item_name', 'quantity', 'loaded_pkg', 'trip_name', 'check_point', 'tracking_position_status', 'narration', 'action'];
  datasource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  editWayBillForm: FormGroup;
  countries: any;
  items: any;
  senders: any;
  receivers: any;
  way_bil: any;
  check_points: any;
  edit_ind_way_bill: any;
  edit_way_bill: any;
  commodities1: any;
  ind_way_bill1: any;
  way_bill_id: any;
  selectedTab: number;
  searchValue: any;
  wayBillNo: any;
  ind_trip: any;
  trip_movement: any;
  origin: any;
  destination: any;
  status: any;
  ind_trip_details: any;
  waybill_trip_view: any;
  trip_exist: boolean;
  additional_data: any;
  from: Date;
  to: Date;
  way_bill_statistics: any;
  searchNameValue: any;
  selected: number;
  year_count: any;
  day_count: any;
  month_count: any;
  average: any;
  month: any;
  today: string;
  agreed: any;
  disagreed: any;
  // from_date: any;
  // to_date: any;
  today_date: string;
  today_date_h: string;
  show_full: boolean;
  detailed: any;
  not_detailed: any[];
  to1: any;
  from1: any;
  loaded_total: number = 0;
  quantity_total: number = 0;

  constructor(private waybillService: WaybillService, private tripServices: TripService, private userService: UserService, private datePipe: DatePipe, private printService: PrintServiceService, private formbuilder: FormBuilder, private toastr: ToastrService, private dialog: MatDialog, private hotkeysService: HotkeysService) {
    this.hotkeysService.add([
      new Hotkey(
        this.saveCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.onSubmitDate();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          // console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );
  }

  ngOnInit(): void {
    this.selected = 0;
    this.show_full = false;
    this.dateCallOnInit();
    // this.getWayBillStatistics();
    // this.getSenders();
    // this.getReceivers();
    // this.getCheckPoints();
    // this.getItems();
    // this.getCountries();
    this.onSubmitDate();
  }

  reloadPage() {
    this.dateCallOnInit();
    // this.getWayBillStatistics();
    // this.getReceivers();
    // this.getSenders();
    // this.getItems();
  }
  dateCallOnInit() {
    let today = new Date();
    this.to = new Date();
    this.from = new Date(today.setDate(today.getDate() - 10));
  }

  getItems() {
    this.waybillService.getItems().subscribe((res: any) => {
      // console.log(res);
      this.items = res.items;
    });
  }
  getCountries() {
    this.userService.getCountries().subscribe((res: any) => {
      // console.log(res);
      this.countries = res.countries;
    });
  }
  getWayBillStatistics() {
    this.from = new Date();
    this.today = this.datePipe.transform(this.from, 'yyyy-MM-dd');
    this.today_date = this.datePipe.transform(this.from, 'dd-MM-yyyy');
    this.month = this.datePipe.transform(this.from, 'MMMM');
    this.waybillService.getWayBillStatistics(this.today).subscribe((res: any) => {
      // console.log(res);
      this.year_count = res.year_count.count;
      this.month_count = res.month_count.count;
      this.day_count = res.day_count.count;
      this.average = res.average;
    });
  }

  getWaybills() {
    this.waybillService.getWayBills().subscribe((res: any) => {
      // console.log(res);
      this.way_bills = res.data;
      this.datasource = new MatTableDataSource(this.way_bills);
      this.datasource.paginator = this.paginator
      this.datasource.sort = this.sort;
    });
  }

  searchByCustomer(key) {
    // // console.log(key);
    this.way_bills = [];
    if (key) {
      this.waybillService.searchWayBillCustomer(key).subscribe((res: any) => {
        // console.log(res);
        this.way_bills = res.data;
        this.showCreation(res.data);
      });
    }
  }
  export() {
    var file_name = 'Way bill';
    const filename = file_name + '.' + 'xlsx';
    let element = document.getElementById('wayBillTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!rows'] = [];
    ws['!rows'][this.datasource.data.length + 1] = { hidden: false };
    ws['!cols'] = [];
    ws['!cols'][11] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, filename);
  }

  searchIndWayBIll(id) {
    this.waybillService.getIndWayBill(id).subscribe((res: any) => {
      // console.log(res);
      this.show_way_bill = true;
      this.way_bill = res.data;
      this.commodities = res.data1;
      this.additional_data = res.additional_data;
      if (this.commoditiesArray.length != 0) {
        this.commoditiesArray.clear();
      }
      this.commodities.forEach(x => {
        this.commoditiesArray.push(this.formbuilder.group(x));
      });
      this.edit_way_bill = this.way_bill;
      this.editWayBillForm.patchValue({
        waybill_no: this.way_bill.waybill_no,
        sender_id: this.way_bill.sender_id,
        receiver_id: this.way_bill.receiver_id,
        origin_check_id: this.way_bill.origin_check_id,
        destination_check_id: this.way_bill.destination_check_id,
        shipment_mode: this.way_bill.shipment_mode,
        weight: this.way_bill.weight,
        measurement_l: this.way_bill.measurement_l,
        measurement_w: this.way_bill.measurement_w,
        measurement_h: this.way_bill.measurement_h,
      });
    }, _error => {
      this.show_way_bill = false;
    });
  }

  onPrintWaybill(waybill_no) {
    // console.log(waybill_no);
    this.printService.printSingleDocument('waybill-print', waybill_no);
  }
  getSenders() {
    this.userService.getSenders().subscribe((res: any) => {
      // console.log(res);
      this.senders = res.senders;
    });
  }
  getReceivers() {
    this.userService.getReceivers().subscribe((res: any) => {
      // console.log(res);
      this.receivers = res.receivers;
    });
  }
  getCheckPoints() {
    this.waybillService.getCheckPoints().subscribe((res: any) => {
      // console.log(res);
      this.check_points = res.check_points;
    })
  }
  onAddNewRow() {
    this.commoditiesArray.push(this.createNewItem());
  }
  onRemoveRow(index) {
    if (this.removedItemsArray.length == 0) {
      this.removedItemsArray.clear();
    }
    if (this.commoditiesArray.value[index].consignment_id == null) {
      this.commoditiesArray.removeAt(index);
    }
    else if (this.commoditiesArray.value[index].loaded == 0) {
      this.commoditiesArray.value[index].quantity = 0;
      this.removedItemsArray.push(this.formbuilder.group(this.commoditiesArray.value[index]));
      // console.log(this.removedItemsArray);
      this.commoditiesArray.removeAt(index);
    } else {
      this.toastr.info("Commodity already added to trip", 'Info')
    }
  }
  get commoditiesArray() {
    return <FormArray>this.editWayBillForm.get("commodities");
  }
  get removedItemsArray() {
    return <FormArray>this.editWayBillForm.get("removed_items");
  }
  createNewItem(): FormGroup {
    return this.formbuilder.group({
      consignment_id: [],
      item_id: [],
      quantity: []
    });
  }
  oneditWayBill() {
    // console.log(this.editWayBillForm.value);
    this.waybillService.editWayBill(this.editWayBillForm.value).subscribe((res: any) => {
      // console.log(res);
      if (res.success) {
        this.toastr.success(res.message, 'Success');
        this.editWayBillForm.reset();
        this.way_bill_id = res.id;
        this.way_bill = res.data.data;
        this.commodities = res.data.data1;
        // console.log(this.way_bill);
        $('#WayBillModal').modal('hide');
        this.getWaybills();
        this.searchByNumber(this.way_bill_id);
        this.searchIndWayBIll(this.way_bill_id);
        this.searchByCustomer(this.way_bill_id);
      } else {
        this.toastr.warning(res.message, 'Warning');
      }
    });

  }
  onChangeTab($event) {
    // console.log($event);
    this.selectedTab = $event;
  }
  onSelectWayBill(waybill) {
    const dialogConfig = new MatDialogConfig();

    // console.log(waybill);
    this.waybill_trip_view = waybill;
    if (waybill.trip_id != null) {
      this.trip_exist = true;
    } else {
      this.trip_exist = false;
    }

    dialogConfig.data = {
      waybill: waybill
    };
    let dialog_ref = this.dialog.open(WayBillmodalComponentComponent, { data: dialogConfig.data, disableClose: true });
  }
  getTripDetails(trip_id) {
    this.tripServices.getTripDetails(trip_id).subscribe((res: any) => {
      // console.log(res);
      this.trip_movement = res.data;
      this.origin = res.origin;
      this.destination = res.destination;
      this.status = res.status;
      this.ind_trip_details = this.trip_movement[this.trip_movement.length - 1];
      // console.log(this.ind_trip_details);
    });
  }
  onCancelEditWayBill() {
    this.getWaybills();
    this.searchByNumber(this.way_bill_id);
    this.searchIndWayBIll(this.way_bill_id);
    this.searchByCustomer(this.way_bill_id);
    this.removedItemsArray.clear();
  }

  onSubmitDate() {
    this.searchValue = [];
    this.searchNameValue = [];
    if (this.from == null || this.to == null) {
      this.toastr.info('Select valid date');
      return;
    }
    const from = this.datePipe.transform(this.from, 'yyyy-MM-dd');
    const to = this.datePipe.transform(this.to, 'yyyy-MM-dd');
    // console.log(from);
    // console.log(to);
    if (new Date(from) > new Date(to)) {
      this.toastr.info('Select valid date');
      return;
    }
    this.waybillService.getWayBillsBetween(from, to).subscribe((res: any) => {
      // console.log(res);
        this.way_bills = res.data;
      this.showCreation(res.data);
    });
  }

  showCreation(details) {
    let waybills = new Array;
    let id = 0;
    let o_id = 0;
    let item_name = '';
    let quantity = 0;
    let loaded_pkg = 0;
    let consignment_id = 0;
    let o_consignment_id = 0;

    details.forEach(element => {
      id = element.id;
      consignment_id = element.consignment_id;

      if (id != o_id) {
        waybills.push(element)
        o_id = element.id;
        o_consignment_id = element.consignment_id;

        item_name = element.item_name;
        quantity = element.quantity;
        loaded_pkg = element.loaded_pkg;
      } else {
        item_name += ', ' + element.item_name;
        loaded_pkg += element.loaded_pkg;
        
        if (consignment_id != o_consignment_id) {
          quantity += element.quantity;
          o_consignment_id = element.consignment_id;
        } 

        let new_value = { 'id': id, 'waybill_date': element.waybill_date, 'waybill_no': element.waybill_no, 'sender_name': element.sender_name, 'receiver_code': element.receiver_code, 'receiver_name': element.receiver_name, 'item_name': item_name, 'quantity': quantity, 'loaded_pkg': loaded_pkg, 'trip_name': element.trip_name, 'check_point': element.check_point, 'tracking_position_status': element.tracking_position_status, 'narration': element.narration }

        waybills[waybills.length - 1] = new_value;
      }
    });

    this.detailed = details;
    this.not_detailed = waybills;

    this.datasource = new MatTableDataSource(waybills);
    this.loaded_total = this.datasource.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
    this.quantity_total = this.datasource.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);
    

    let data = [];
    if (this.show_full) {
      data = this.detailed
    } else {
      data = this.not_detailed
    }

    this.datasource = new MatTableDataSource(data);
    this.datasource.paginator = this.paginator
    this.datasource.sort = this.sort;
  }

  showControl() {
    let data = [];
    if (this.show_full) {
      this.show_full = false;
      data = this.not_detailed
    } else {
      this.show_full = true;
      data = this.detailed
    }

    this.datasource = new MatTableDataSource(data);
    this.datasource.paginator = this.paginator
    this.datasource.sort = this.sort;
  }

  // showControl() {
  //   let data = [];
  //   if (this.show_full) {
  //     this.show_full = false;
  //     data = this.not_detailed
  //   } else {
  //     this.show_full = true;
  //     data = this.detailed
  //   }

  //   this.datasource = new MatTableDataSource(data);
  //   this.datasource.sort = this.sort;
  // }

  /**
   * search waybill by waybill_number
   * 
   * @param id 
   */
  searchByNumber(id) {
    // console.log(id);
    this.way_bills = [];
    if (id) {
      this.waybillService.searchWayBillNumber(id).subscribe((res: any) => {
        // console.log(res);
        this.way_bills = res.data;
        this.showCreation(res.data);

        // this.datasource = new MatTableDataSource(res.data);
        // this.datasource.sort = this.sort;
      });
    }
    // this.datasource = new MatTableDataSource(this.way_bills);
    // this.datasource.sort = this.sort;
  }
  onChange($event) {
    // console.log($event);
    this.selected = $event;
  }
  onVoted(agreed: boolean) {
    agreed ? this.agreed++ : this.disagreed++;
  }
  getQuantityTotal1() {
    return this.datasource.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);
  }
  getLoadedTotal1() {
    return this.datasource.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
  }

  //  /**
  //  * Re print waybill
  //  */
  //   rePrintWayBill() {
  //     this.way_bills[0]
  //     // console.log(this.way_bills[0].waybill_no);
  //     this.printService.printDocument('waybill-print', this.way_bills[0].waybill_no); //waybill-print
  //   }

  /**
   * to print waybill
   */
  printWayBill() {
    let from_day = this.datePipe.transform(this.from, 'yyyy-MM-dd');
    let to_day = this.datePipe.transform(this.to, 'yyyy-MM-dd');
    // console.log(from_day);

    this.printService.printUsingTwoParameter('waybill-print', from_day, to_day); //waybill-print
  }

  /**
   * Excel creation
   */
  async generateExcel() {

    // console.log(this.datasource.data);
    // // console.log(this.today_date);
    // // console.log(this.today_date_h);
    // console.log(this.way_bills);

    this.from = new Date();
    this.today_date = this.datePipe.transform(this.from, 'dd/MM/yyyy');
    this.today_date_h = this.datePipe.transform(this.from, 'dd-MM-yyyy');

    // Excel Title, Header, Data
    const title = 'Inshah ALLAH .. Every Move A Success';
    const header = ['DATE', 'CONS#', 'SHIPPER', 'CONSIGNEE', 'COMMODITY', 'PKG', 'REMARKS'];

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('DAILY NOTE ' + this.today_date_h);


    // Add Row and formatting
    const titleRaw = worksheet.addRow([title]);
    titleRaw.font = { name: 'Calibri', family: 4, size: 16, underline: 'none', bold: false };
    titleRaw.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells('A1:G1');

    const rowValues = [];
    rowValues[1] = 'DATE ' + this.today_date;
    rowValues[4] = 'DAILY RECEIVED ITEMS';

    const subTitleRow = worksheet.addRow(rowValues);
    subTitleRow.font = { name: 'Verdana', family: 4, size: 12, underline: 'none', bold: true };
    subTitleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

    rowValues[1] = '';
    rowValues[4] = 'GOLDEN ELITE CARGO';

    const subTitleRow1 = worksheet.addRow(rowValues);
    subTitleRow1.font = { name: 'Verdana', family: 4, size: 11, underline: 'none', bold: false };

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Verdana', family: 4, size: 11, color: { argb: '#FF0000' }, underline: 'none', bold: true };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Add Data and Conditional Formatting
    // this.way_bills.forEach(d => {
    //   // console.log(d);

    //   const row = worksheet.addRow(d);
    //   const qty = row.getCell(5);
    //   let color = 'FF99FF99';
    //   if (+qty.value < 500) {
    //     color = 'FF9999';
    //   }

    //   qty.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: color }
    //   };
    // }

    // );

    this.way_bills.forEach(d => {
      const rowValues = [];
      rowValues[1] = this.datePipe.transform(d.waybill_date, 'dd/MM/yyyy');
      rowValues[2] = d.receiver_code + '/' + d.waybill_no;
      rowValues[3] = d.sender_name;
      rowValues[4] = d.receiver_name;
      rowValues[5] = d.item_name;
      rowValues[6] = d.loaded_pkg;
      rowValues[7] = d.narration;
      const contentRow = worksheet.addRow(rowValues);
      contentRow.font = { name: 'Verdana', family: 4, size: 10, underline: 'none', bold: false };

      // Cell Style : Fill and Border
      contentRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
          // bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    const rowValues1 = [];
    var emptyRow = worksheet.addRow(rowValues1);
    emptyRow.font = { name: 'Verdana', family: 4, size: 10, underline: 'none', bold: false };
    // Cell Style : Fill and Border
    emptyRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(7).width = 50;

    // Footer Row
    const footeValues = [];
    // footeValues[6] = worksheet.getCell(`=SUM(F6:F${footerRow.number})`);
    const footerRow = worksheet.addRow(footeValues);

    footerRow.getCell(6).value = this.loaded_total;
    // footerRow.getCell(6).value = this.getLoadedTotal1();
    footerRow.font = { name: 'Verdana', family: 4, size: 20, underline: 'none', bold: true };
    footerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    // worksheet.getCell('B1').value = {formula : 'SUM(A1,A2)', result: (worksheet.getCell('A1').value + worksheet.getCell('A2').value) }


    // Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'DAILY RECEIVED.xlsx');
    });
  }

}
