<div class="wrapper wrapper-full-page">
  <div filter-color="black" class="page-header register-page header-filter"
    style="background-image: url('../../assets/img/cover.jpeg'); background-size: cover; background-position: top center; min-height: 100vh;">
    <div class="container">
      <div class="row">
        <div class="col-md-10 ml-auto mr-auto">
          <div class="card card-signup">

            <h2 class="card-title text-center mt-5">Register</h2>
            <div class="card-body">
              <div class="row">
                <div class="col-md-5 ml-auto">
                  <div class="info info-horizontal">
                    <div class="icon icon-rose"><i class="material-icons">timeline</i></div>
                    <div class="description">
                      <h4 class="info-title">loremheader</h4>
                      <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae </p>
                    </div>
                  </div>
                  <div class="info info-horizontal">
                    <div class="icon icon-primary"><i class="material-icons">code</i></div>
                    <div class="description">
                      <h4 class="info-title">lorem header</h4>
                      <p class="description"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. . </p>
                    </div>
                  </div>
                  <div class="info info-horizontal">
                    <div class="icon icon-info"><i class="material-icons">group</i></div>
                    <div class="description">
                      <h4 class="info-title">lorem header</h4>
                      <p class="description"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ratione
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-5 mr-auto text-center">
                  <form [formGroup]="SignupFormGroup" (ngSubmit)="onSignup()">
                    <mat-horizontal-stepper #stepper>
                      <mat-step>
                        <div class="social text-center"><button mat-raised-button=""
                            class="btn btn-just-icon btn-round btn-twitter mat-raised-button mat-button-base"><span
                              class="mat-button-wrapper"><i class="fa fa-google"></i></span>
                            <!-- (click)="socialSignIn('google')" -->
                            <div matripple="" class="mat-ripple mat-button-ripple"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button><button mat-raised-button=""
                            class="btn btn-just-icon btn-round btn-dribbble mat-raised-button mat-button-base"><span
                              class="mat-button-wrapper"><i class="fa fa-linkedin"></i></span>
                            <div matripple="" class="mat-ripple mat-button-ripple"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button><button mat-raised-button=""
                            class="btn btn-just-icon btn-round btn-facebook mat-raised-button mat-button-base"><span
                              class="mat-button-wrapper"><i class="fa fa-facebook"></i></span>
                            <div matripple="" class="mat-ripple mat-button-ripple"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button>

                        </div>

                        <mat-form-field appearance="outline">
                          <mat-label>Full Name</mat-label>
                          <input matInput placeholder="Full Name" formControlName="faculty_name">
                        </mat-form-field>


                        <mat-form-field appearance="outline">
                          <mat-label>Username</mat-label>
                          <input matInput placeholder="Username" formControlName="faculty_username">
                          <mat-hint><strong>(eg: Mobile number)</strong> </mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Gender</mat-label>
                          <mat-select formControlName="faculty_gender">
                            <mat-option value="M">
                              Male
                            </mat-option>
                            <mat-option value="F">
                              Female
                            </mat-option>
                            <mat-option value="O">
                              Other
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>Joining Date</mat-label>
                          <input matInput [matDatepicker]="epicker" formControlName="faculty_join_date"
                            #faculty_join_date>
                          <mat-datepicker-toggle matSuffix [for]="epicker"></mat-datepicker-toggle>
                          <mat-datepicker #epicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Designation</mat-label>
                          <mat-select formControlName="designation_id">
                            <mat-option *ngFor="let desig of designation_list" [value]="desig.designation_id">
                              {{desig.designation}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button class="nxt float-right" mat-icon-button color="primary" matStepperNext>
                          <mat-icon>arrow_forward_ios</mat-icon>
                        </button>
                      </mat-step>
                      <mat-step>
                        <mat-form-field appearance="outline">
                          <mat-label>Select Department</mat-label>
                          <mat-select formControlName="dep_id">
                            <mat-option *ngFor="let dep of departments" [value]="dep.dep_id">
                              {{dep?.dep_name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Existing Mail Address</mat-label>
                          <input matInput placeholder="Existing Mail Address" formControlName="faculty_email">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Mobile Number</mat-label>
                          <input matInput placeholder="Mobile Number" formControlName="faculty_mobile">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Address</mat-label>
                          <input matInput placeholder="Address" formControlName="faculty_address">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Select Staff Type</mat-label>
                          <mat-select formControlName="faculty_type">
                            <mat-option *ngFor="let staff_type of staff_types" [value]="staff_type.value">
                              {{staff_type.viewValue}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button mat-icon-button color="primary" class="nxt float-left" matStepperPrevious>
                          <mat-icon>arrow_back_ios</mat-icon>
                        </button>
                        <button type="submit" class="float-right sng mt-2" [disabled]="signup_disable" mat-flat-button
                          color="primary">Sign Up</button>
                      </mat-step>
                    </mat-horizontal-stepper>
                  </form>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>