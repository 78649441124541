import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';

@Component({
  selector: 'app-manifesto-print',
  templateUrl: './manifesto-print.component.html',
  styleUrls: ['./manifesto-print.component.scss']
})
export class ManifestoPrintComponent implements OnInit {
  trip_id: any;
  ind_trip: any;
  consignments: any;
  total_quantity: number;
  total_loaded: number;

  constructor(private tripService: TripService, route: ActivatedRoute, private printservice: PrintServiceService) { 
    this.trip_id = route.snapshot.params['trip_id'];
    // console.log(this.trip_id);
  }

  ngOnInit(): void {

    this.tripService.getTripWayBills(this.trip_id).subscribe((res: any) => {
      // console.log(res);

      this.ind_trip = res.trip;
      this.consignments = res.arranged_data;
      this.total_quantity = 0;
      this.total_loaded = 0;

      for (var i = 0; i < this.consignments.length; i++) {
        this.total_quantity += this.consignments[i].quantities;
        this.total_loaded += this.consignments[i].loaded_pkgs;
      }

      var file_name = "Manifesto " + this.ind_trip.trip_name
      this.printservice.onDataReady(file_name,'manifesto-print','landscape');
      
    });

  }

}
