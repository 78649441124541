import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  readonly rootUrl = environment.rootUrl;
  
  constructor(private http: HttpClient) { }

  getRoleMenuItems() {
    return this.http.get<any>(this.rootUrl + '/general/get-rolebased-menu')
  }

   // Get quick access menu
   getQuickAccessMenu() {
    return this.http.get<any>(this.rootUrl + '/general/get-quick-menu');
  }

  /* 
   Update quick access menu
  */
  updateQuickAccess(data) {
    return this.http.post<any>(this.rootUrl + '/general/update-quick-menu', data)
  }
}
