
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { Component, OnInit } from '@angular/core';
import { TripService } from 'app/services/trip.service';
import { UserService } from 'app/services/user.service';


  
  @Component({
    selector: 'app-invoicestatement-print',
    templateUrl: './invoicestatement-print.html',
    styleUrls: ['./invoicestatement-print.scss']
  })
  export class InvoiceStatementComponent implements OnInit {
    customer_id: any;
    customer_name:any;
    customer_address:any;
    invoices: any;
    invoice_total: any;
    total_paid: any;
    total_balance: any;
    total_pending: number;
    on_account_advance: number;
    on_account_advances: any;
    invoice_statement: any;
  
    constructor( private tripService: TripService, private printservice: PrintServiceService,route: ActivatedRoute) { 
        this.customer_id = route.snapshot.params['customer_id'];
        this.customer_name = route.snapshot.params['customer_name'];
        this.customer_address = route.snapshot.params['customer_address'];
    }
 
 
 ngOnInit(): void {
    this.tripService.getCustomerStatements(this.customer_id).subscribe((res: any) => {
        console.log(res);
        this.invoices = res.invoices;
        this.on_account_advances = res.on_account;
        this.invoice_total = res.invoice_total;
        this.total_paid = res.total_paid;


        
  
        this.setOnAccountAdvances()
  
        this.total_pending = this.invoice_total - this.total_paid;
        this.total_balance = this.invoice_total - this.total_paid - this.on_account_advance;
      });
      
      
    }
  
    setOnAccountAdvances() {
      this.on_account_advance = 0
      for (let i = 0; i < this.on_account_advances.length; i++) {
        // console.log(this.on_account_advances[i]);
        this.on_account_advance += parseFloat(this.on_account_advances[i].balance);
        if (this.on_account_advances[i].balance <= 0) {
          this.on_account_advances.splice(i, 1)
          i--;
        }
      }
      
      // console.log(this.on_account_advances);
    

    var file_name = "" + this.customer_name
    this.printservice.onDataReady(file_name,'invoicestatement-print','landscape');
  }
  calculateDiff(due_date) {
    let date = new Date(due_date);
    let currentDate = new Date();

    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
 
}
