import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from 'app/auth/authservice.service';
// import * as $ from 'jquery';
import { DialogService } from 'app/services/dialog.service';
import { GlobalConstants } from '../../common/global-constants';
import { MenuService } from 'app/services/menu.service';

declare var $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: any[] = [
  { path: '/fee-invoice', title: 'Fee Invoice', icon: 'receipt', class: '' },
  { path: '/invoice-verification', title: 'Invoice Verification', icon: 'assessment', class: '' },
  { path: '/fine-penalties', title: 'Fine / Penalties', icon: 'assessment', class: '' },
  { path: '/collection', title: 'Collection Register', icon: 'assessment', class: '' },
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  isConnected = true;

  constructor(private router: Router, private authservice: AuthserviceService, private dialog: DialogService,
    private menuSrvice: MenuService) { }

  ngOnInit() {
    this.menuSrvice.getRoleMenuItems().subscribe(res => {
      this.menuItems = res.side_menu;
      // // console.log(this.menuItems);
      const arr = [];
      var arr1 = [];
      this.menuItems.filter(x => {
        x.childs.map(y => {
          arr.push('/' + y.sub_menu_url)
        })
      })

      arr1 = arr.concat(GlobalConstants.path_list);
      GlobalConstants.path_list = arr1;
    })
    // this.checkConnection();

  }
  // checkConnection() {
  //   this.connection.monitor().subscribe(isConnected => {
  //     this.isConnected = isConnected;
  //     // console.log(this.isConnected);
  //   })
  // }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  logout() {
    var dialogTitle = "Do you want to logout";
    var dialogText = "Are you sure ?";
    this.dialog.openDialogConfirm(dialogTitle, dialogText).afterClosed().subscribe(res => {
      if (res) {
        this.authservice.logout().subscribe(success => {
          if (success) {
            this.router.navigate((['/signin']));
          } else {
            this.router.navigate(['/signin']);
          }
        });
      }
    })
  }
}
