import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WaybillService {

  readonly rootUrl = environment.rootUrl;

  constructor(private http: HttpClient) { }

  /**
   * Get all waybill between dates
   */
  getWayBillsBetween(from, to) {
    return this.http.get<any>(this.rootUrl + '/waybill/get-way-bills-between/' + from + '/' + to);
  }

  /**
   * 
   */
  getItems() {
    return this.http.get<any>(this.rootUrl + '/items/get-items');
  }

  /**
   * 
   */
  getAllItems() {
    return this.http.get<any>(this.rootUrl + '/items/get-all-items');
  }

  /**
   * 
   * @param data 
   */
  addItem(data) {
    return this.http.post<any>(this.rootUrl + '/items/add-item', data);
  }

  /**
   * 
   * @param data 
   */
  editItem(data) {
    return this.http.post<any>(this.rootUrl + '/items/edit-item', data);
  }

  editTracking(data1) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-trip-position', { data: data1 });
  }

  editConsignmentTrack(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-consignment-track', { data });
  }



  /**
   * 
   * @param data 
   * @returns 
   */
  editItemCustomerRate(data) {
    return this.http.post<any>(this.rootUrl + '/items/edit-item-customer-rate', data);
  }

  /**
   * 
   */
  getWayBillStatistics(today) {
    return this.http.get<any>(this.rootUrl + '/waybill/get-way-bill-statistics/' + today);
  }

  /**
   * 
   */
  getWayBills() {
    return this.http.get<any>(this.rootUrl + '/waybill/get-way-bills');
  }

  /**
   * 
   */
  searchWayBillCustomer(key) {
    return this.http.get<any>(this.rootUrl + '/waybill/search-way-bill-customer/' + key);
  }

  /**
   * 
   */
  getIndWayBill(waybill_no) {
    return this.http.get<any>(this.rootUrl + '/waybill/get-ind-way-bill/' + waybill_no);
  }

  /**
   * 
   */
  getCheckPoints() {
    return this.http.get<any>(this.rootUrl + '/trip/get-check-points');
  }

  /**
   * 
   */
  editWayBill(data) {
    return this.http.post<any>(this.rootUrl + '/waybill/edit-way-bill', data);
  }

  /**
   * 
   */
  editWayBillNarration(data) {
    return this.http.post<any>(this.rootUrl + '/waybill/edit-way-bill-narration', data);
  }

  /**
   * 
   * @param id 
   */
  addWayBill(data) {
    return this.http.post<any>(this.rootUrl + '/waybill/add-way-bill', data);
  }

  /**
   * 
   */
  searchWayBillNumber(id) {
    return this.http.get<any>(this.rootUrl + '/waybill/search-way-bill-number/' + id);
  }

  /**
   * 
   */
  searchWayBill(details) {
    return this.http.post<any>(this.rootUrl + '/waybill/search-way-bill', { details: details });
  }

  /**
 * 
 */
  searchMobile(details) {
    return this.http.post<any>(this.rootUrl + '/waybill/search-way-bill-mobile-number', { details: details });
  }
  /**
   * to get commodities
   */
  getcommodities() {
    return this.getAllItems();
    // return this.http.get<any>(this.rootUrl + '/get-all-items');
  }

  /**
   * to get commodities with customer rate
   */
  getcommoditiesWithCustomerRate(customer_id) {
    return this.http.get<any>(this.rootUrl + '/items/get-items-customer-rate/' + customer_id);
  }

  /**
   * to add new commodity
   * 
   * @param data 
   */
  addCommodity(data) {
    return this.http.post<any>(this.rootUrl + '/items/add-item', data);
  }

  /**
   * 
   * @param data 
   */
  editCommodity(data) {
    return this.http.post<any>(this.rootUrl + '/items/edit-item', data);
  }

  /**
  * to get last generated way-bill
  */
  getLastGeneratedNo() {
    return this.http.get<any>(this.rootUrl + '/waybill/last-waybill-number');
  }

  /**
   * 
   */
  searchItem(key) {
    return this.http.post<any>(this.rootUrl + '/items/search-item', { key: key });
  }

  /**
   * 
   */
  searchSenders(key) {
    return this.http.post<any>(this.rootUrl + '/user/search-senders', { key: key });
  }

  /**
   * 
   */
  searchReceivers(key) {
    return this.http.post<any>(this.rootUrl + '/user/search-receivers', { key: key });
  }

  /**
   * 
   */
  searchCheckPoints(key) {
    return this.http.post<any>(this.rootUrl + '/trip/search-check-points', { key: key });
  }

  /**
   * to get waybills of a date
   * 
   * @param date 
   */
  getWaybillByDate(date) {
    return this.http.get<any>(this.rootUrl + '/waybill/get-waybill-by-date/' + date);
  }

  /**
   * 
   */
  getFullConsignmentOnBoard() {
    return this.http.get<any>(this.rootUrl + '/consignment/get-full-consignment-on-board');
  }

  /**
   * 
   * @param from 
   * @param to 
   */
  getConsignmentOnBoard(from, to) {
    return this.http.get<any>(this.rootUrl + '/consignment/get-consignment-on-board/' + `${from}/${to}`);
  }

  /**
   * 
   * @param from 
   * @param to 
   */
  getConsignmentHistory(from, to) {
    return this.http.get<any>(this.rootUrl + '/consignment/get-consignment-history/' + `${from}/${to}`);
  }


  getManifestoConsignmentOnBoard() {
    return this.http.get<any>(this.rootUrl + '/consignment/get-manifesto-consignment-on-board');
  }

  getManifestoEditConsignmentOnBoard(id, trip_id) {
    return this.http.get<any>(this.rootUrl + '/consignment/get-manifesto-edit-consignment-on-board/' + id + '/' + trip_id);
  }


}
