import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VatPurchaseService {
  private apiUrl = '/vat_purchases'; 

  constructor(private http: HttpClient) { }

  getAllVatPurchases() {
    return this.http.get<any[]>(`${environment.rootUrl}${this.apiUrl}`);
  }
  getStatements(company,from,to) {
    return this.http.get<any[]>(`${environment.rootUrl}${this.apiUrl}`+'/statements/'+company+'/'+from+'/'+to);
  }

  getVatPurchaseDetails(id) {
    return this.http.get<any[]>(`${environment.rootUrl}${this.apiUrl}`+'/show/'+id);
  }

  getNextPurchaseId(prefix) {
    return this.http.get<any>(`${environment.rootUrl}${this.apiUrl}`+'/invoice_no?prefix='+prefix);
  }
   
  addVatPurchase(data) {
    return this.http.post<any>(`${environment.rootUrl}${this.apiUrl}`+'/store',data);
  }

  updateVatPurchase(id,data) {
    return this.http.put<any>(`${environment.rootUrl}${this.apiUrl}`+`/update/${id}`,data);
  }

}
