import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-waybill-rate-calculation',
  templateUrl: './waybill-rate-calculation.component.html',
  styleUrls: ['./waybill-rate-calculation.component.scss']
})
export class WaybillRateCalculationComponent implements OnInit {
  waybillForm: FormGroup;
  total: number;
  rate: number = 100;
  add_disable = false;
  sub_total: number;


  constructor(private formbuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.waybillForm = this.formbuilder.group({
      measurement_l: ['', Validators.required],
      measurement_w: ['', Validators.required],
      measurement_h: ['', Validators.required],
      weight: [1, Validators.required],
    })
  }
  OnSubmit() {
    // console.log(this.waybillForm.value);

    let l = this.waybillForm.value.measurement_l / 100;
    let w = this.waybillForm.value.measurement_w / 100;
    let h = this.waybillForm.value.measurement_h / 100;
    let weight = this.waybillForm.value.weight;

    // console.log(l);
    // console.log(w);
    // console.log(h);
    // console.log(weight);


    this.sub_total = Number((l * w * h).toFixed(3));

    this.total = Number((l * w * h * weight).toFixed(3));

    // console.log(this.sub_total);
    // console.log(this.total);
  }


}