<div class="main-content image A">
  <div class="container main-div">
    <div class="col-md-6" [hidden]="forget">
      <div class="card loginbox" style="box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75); background: rgba(225, 225, 225, 0.64);">
        <div class="row card-header card-header-icon justify-content-center">
          <!-- <div>
            <img src=".././../assets/img/invoice-1.jpg" class="company-header-avatar" alt="Logo">
          </div> -->
          <h3 class="card-title text-center">{{institution_name}}</h3>
        </div>

        <div class="card-body">
          <form [formGroup]="loginform" (ngSubmit)="checkLogin()">
            <div class="row form-div">
              <div class="col-md-12 field">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Username" formControlName="email" #email autocomplete="off">
                  <mat-icon matSuffix>person</mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-12 field space">
                <mat-form-field>
                  <input matInput placeholder="Enter your password" [type]="hide ? 'password' : 'text'"
                    formControlName="password" #Password>
                  <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"
                *ngIf="loginform.controls['email'].value !== '' && loginform.controls['password'].value !== ''">
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button mat-raised-button [disabled]="loginform.invalid" type="submit" class="btn btn-primary btn-block"
                  [disabled]="submitted">
                  Login</button>
              </div>

              <div class="col-12 text-center">
                <a class="link" href="javascript:void(0)" (click)="forget=!forget"> Forgot password</a> ?<br>
              </div>
              <mat-progress-bar *ngIf="submitted" mode="indeterminate"></mat-progress-bar>
            </div>
          </form>

        </div>
      </div>
    </div>

    <div class="col-md-6" [hidden]="!forget">
      <div class="card">
        <div class="card-header card-header-icon card-header-rose">
          <div class="card-icon">
            <i class="material-icons">lock</i>
          </div>
          <h4 class="card-title">Reset Password</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="resetform" (ngSubmit)="requestResetEmail()">
            <div class="row form-div">
              <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Enter Registered E-mail" type="email" formControlName="email" #email1>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center">
                <button mat-raised-button type="submit" class="btn btn-primary btn-block">
                  Reset
                </button>
              </div>
              <div class="col-12 text-center" *ngIf="is_mobile_number">
                <a class="link text-primary" href="javascript:void(0)"
                  (click)="is_mobile_number = false; resetform.reset(); ask_user_id = false;">Reset using
                  E-mail</a>
              </div>
              <div class="col-12 text-center mt-2">
                <a class="link" href="javascript:void(0)" (click)="forget=!forget"> Back to Login </a>!
              </div>
              <mat-progress-bar *ngIf="submitted" mode="indeterminate"></mat-progress-bar>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>