<div class="main-content body" id="manifesto-print">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="text-center"><b>Trip No:{{ind_trip?.trip_no}} - Trip Name:{{ind_trip?.trip_name}}</b></h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table table-bordered ml-2 mr-1" style="line-height: 90%;">
          <thead>
            <tr>
              <th>Date</th>
              <th style="white-space: nowrap;">Vehicle Name</th>
              <th>Contact</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Trip Position</th>
              <th>Status</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="nowrap">{{ind_trip?.date | date:'dd-MM-yyyy'}}</td>
              <td>{{ind_trip?.vehicle_name}}</td>
              <td>{{ind_trip?.contact_name}}</td>
              <td>{{ind_trip?.start_check_name}}</td>
              <td>{{ind_trip?.end_check_name}}</td>
              <td>{{ind_trip?.check_point}}</td>
              <td>{{ind_trip?.tracking_position_status}}</td>
              <td>{{ind_trip?.details}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table table-bordered ml-2 mr-1" style="line-height: 90%;">
          <thead>
            <tr>
              <th class="text-center" style="width: 9%;">Date</th>
              <th>Way Bill</th>
              <th>Sender</th>
              <th>Reciever</th>
              <th>Commodity</th>
              <th class="text-center" style="width: 5%;">PKG</th>
              <th class="text-center" style="width: 7%;">Loaded</th>
              <th class="text-center" style="width: 7%;">Balance</th>
              <th>Narration</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let element of consignments">
              <td class="nowrap">{{element?.waybill_date | date:'dd-MM-yyyy'}}</td>
              <td class="dont-break-out">
                {{element?.receiver_code?element?.receiver_code+'/':''}}{{element?.waybill_no}}</td>
              <td class="dont-break-out">{{element?.sender_name}}</td>
              <td class="dont-break-out">{{element?.receiver_name}}</td>
              <td>{{element?.item_names}}</td>
              <td>{{element?.quantities}}</td>
              <td>{{element?.loaded_pkgs}}</td>
              <td>{{element?.balance}}</td>
              <td class="dont-break-out">{{element?.narrations? element?.narrations+', ':''}}{{element?.narration}}</td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td><b>Total</b></td>
              <td><b>{{total_quantity}}</b></td>
              <td><b>{{total_loaded}}</b></td>
              <td><b></b></td>
              <td><b></b></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>