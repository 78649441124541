import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { VatPurchaseService } from 'app/services/vat-purchase.service';

@Component({
  selector: 'app-vat-purchase-print',
  templateUrl: './vat-purchase-print.component.html',
  styleUrls: ['./vat-purchase-print.component.scss']
})
export class VatPurchasePrintComponent implements OnInit {

  invoice_id: any;
  individual_purchase: any[];
  head_data: any;
  words_amount: any;
  total_qty:any;
  total_gross:any;
  total_vat:any;
  total_tax:any;

  constructor(private vatService: VatPurchaseService, route: ActivatedRoute, private printservice: PrintServiceService) {
    this.invoice_id = route.snapshot.params['invoice_id'];
    
  }

  ngOnInit(): void {
    
    this.vatService.getVatPurchaseDetails(this.invoice_id).subscribe((res: any) => {
      this.head_data = res.vat_purchase;
      this.individual_purchase = res.vat_purchase_details;
      
      this.total_gross = this.individual_purchase.reduce((acc, curr) => {
        return acc + Number(curr.gross_amount);
      }, 0);
      this.total_vat = this.individual_purchase.reduce((acc, curr) => {
        return acc + Number(curr.vat_amount);
      }, 0);
      this.total_tax = this.individual_purchase.reduce((acc, curr) => {
        return acc + Number(curr.total);
      }, 0);
      this.words_amount = res.amount_words;
      if(this.individual_purchase.length <10){
        let fillCount = 10 - this.individual_purchase.length;
        let filledArray = new Array(fillCount).fill(undefined);
        this.individual_purchase = this.individual_purchase.concat(filledArray);
      }

      var file_name = "Vat Purchase " + this.invoice_id
      this.printservice.onDataReady(file_name,'printSection','A4');
    });
  }

}
