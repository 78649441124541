import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-box-dialog',
  templateUrl: './confirm-box-dialog.component.html',
  styleUrls: ['./confirm-box-dialog.component.scss']
})

export class ConfirmBoxDialogComponent implements OnInit {

  dialogTitle: string;
  dialogText: string;
  @Output() submitClicked = new EventEmitter<any>();

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data,
    public dialogref: MatDialogRef<ConfirmBoxDialogComponent>) { }

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle;
    this.dialogText = this.data.dialogText;
  }

  closeDialog() {
    this.dialogref.close(false);
  }

}
