import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'app/services/loader.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading=false;
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      // // console.log(v);
      this.loading = v;
    });
  }
  ngOnInit() {
  }


}
