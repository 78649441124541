import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  readonly rootUrl = environment.rootUrl;

  constructor(private http: HttpClient) { }

  /* 
  * To get student count of in a paper
  * batch_id and term_id parameters required
  */
  getBatchTermPapersStudentCount(batch_id, term_id) {
    return this.http.get<any>(this.rootUrl + '/paper/batch-term-papers-std-count/' + batch_id + '/' + term_id);
  }

  /* 
  * To get Assigned students of a paper
  * paper_id parameter required
  */
  getAssignedPaperStudents(paper_id) {
    return this.http.get<any>(this.rootUrl + '/paper/paper-students/' + paper_id);
  }

  /* 
  * To get UnAssigned students of a paper
  * paper_id and batch_id parameters required
  */
  getUnAssignedPaperStudents(batch_id, paper_id) {
    return this.http.get<any>(this.rootUrl + '/paper/paper-unassigned-students/' + batch_id + '/' + paper_id);
  }

  /* 
  * To Add students to a paper
  * paper_id and students parameters required
  */
  addPaperStudents(paper_id, students) {
    return this.http.post<any>(this.rootUrl + '/paper/add-paper-students/' + paper_id, { students: students });
  }

  /* 
  * To update students of a paper
  * paper_id and students parameters required
  */
  updatePaperStudents(paper_id, students) {
    return this.http.post<any>(this.rootUrl + '/paper/update-paper-students/' + paper_id, { students: students });
  }

  /* 
  * To remove students from a paper
  * paper_id and students parameters required
  */
  removePaperStudents(paper_id, students) {
    return this.http.post<any>(this.rootUrl + '/paper/remove-paper-students/' + paper_id, { students: students });
  }

  /* 
  * To get papers of a student
  * student_id parameter required
  */
  getStudentPaper(student_id) {
    return this.http.get<any>(this.rootUrl + '/paper/get-student-papers/' + student_id);
  }

  /* 
  * To add new paper for student
  * student_id and paper_ids parameters required
  */
  addStudentPaper(student_id, paper_ids) {
    return this.http.post<any>(this.rootUrl + '/paper/add-student-papers/' + student_id, { paper_ids: paper_ids });
  }

  /* 
  * To remove paper of a student
  * student_id and paper_ids parameters required
  */
  removeStudentPaper(student_id, paper_allocation_ids) {
    return this.http.post<any>(this.rootUrl + '/paper/remove-student-papers/' + student_id, { paper_allocation_ids: paper_allocation_ids });
  }

  /* 
  * To get papers of a batch
  * batch_id and term_id parameters required
  */
  getBatchTermPapers(batch_id, term_id) { //batch-term-active-papers
    return this.http.get<any>(this.rootUrl + '/paper/batch-term-active-papers/' + batch_id + '/' + term_id);
  }

  /* 
  * To get assessments of papers
  * parameters not required
  */
  getPapaerAssessments() {
    return this.http.get<any>(this.rootUrl + '/paper/get-assessments');
  }

  /* 
  * To get assessments of papers
  * parameters not required
  */
  addAssessments(assessment) {
    return this.http.post<any>(this.rootUrl + '/paper/add-assessment', { assessment: assessment });
  }

  /* 
  * To get all assessments
  * parameters not required
  */
  getAllAssessments() {
    return this.http.get<any>(this.rootUrl + '/paper/get-all-assessments');
  }

  /* 
  * To get live assessments
  * parameters not required
  */
  getLiveAssessments() {
    return this.http.get<any>(this.rootUrl + '/paper/get-assessments');
  }

  /* 
  * To get papers of batch
  * batch and term parameters required
  */
  getAllBatchTermPaper(batch, term) {
    return this.http.get<any>(this.rootUrl + '/paper/batch-term-papers/' + `${batch}/${term}`);
  }

  /* 
  * To get mark entry status
  * batch and term parameters required
  */
  getMarkEntryStatus(adm_year, semester) {
    return this.http.get<any>(this.rootUrl + '/paper/get-mark-entry-status/' + `${adm_year}/${semester}`);
  }

  /* 
  * To update mark entry status
  * data,batch and term parameters required
  */
  updateMarkEntryStatus(data, adm_year, semester) {
    return this.http.post<any>(this.rootUrl + '/paper/update-mark-entry-status', { data: data, adm_year: adm_year, semester: semester });
  }

  /* 
  * To update Assessment status
  * data parameters required
  */
  updateAssessmentStatus(data) {
    return this.http.post<any>(this.rootUrl + '/paper/update-assessment-status', { id: data.id, status: data.status });
  }

  //To assign assessment to paper
  addPaperAndAssessment(data, paper_id) {
    return this.http.post<any>(this.rootUrl + '/paper/add-paper-assessment-assignment', { data: data, paper_id: paper_id });
  }

  //To update assessment to paper
  updatePaperAndAssessment(data, paper_id) {
    // console.log(data);
    return this.http.post<any>(this.rootUrl + '/paper/update-paper-assessment-assignment', { data: data, paper_id: paper_id });
  }

  //To assign all students to all paper
  assignStudentsAll(batch_id, term) {
    return this.http.post<any>(this.rootUrl + '/paper/assign-students-to-all-papers', { batch_id: batch_id, semester: term });
  }

  
  // getGroups() {
  //   return this.http.get<any>(this.rootUrl + '/get-group');
  // }

  getLedgers() {
    return this.http.get<any>(this.rootUrl + '/ledger/get-ledger');
  }
  // getLedgers() {
  //   return this.http.get<any>(this.rootUrl + '/get-ledger');
  // }

  
  // getSubLedger(ledger_id) {
  //   return this.http.get<any>(this.rootUrl + '/get-sub-ledger-by-ledger/' + ledger_id);
  // }

  

  

  

  removePaperAssessment(paper_assm_id) {
    return this.http.post(this.rootUrl + '/paper/remove-paper-assessment', { paper_assm_id: paper_assm_id });
  }

  transferFacultyClub(new_id, club) {
    return this.http.post<any>(this.rootUrl + '/faculty/transfer-faculty-club', { faculty_id: new_id, club: club });
  }

  transferFacultyTutor(old, new_id, tutorship) {
    return this.http.post<any>(this.rootUrl + '/faculty/transfer-faculty-tutor', { old_faculty_id: old, faculty_id: new_id, tutorship: tutorship });
  }

  transferFacultyPaper(old, new_id, paper) {
    return this.http.post<any>(this.rootUrl + '/faculty/transfer-faculty-paper', { old_faculty_id: old, faculty_id: new_id, paper: paper });
  }

  transferFacultyRoles(new_id, faculty_role) {
    return this.http.post<any>(this.rootUrl + '/faculty/transfer-faculty-role', { faculty_id: new_id, faculty_role: faculty_role });
  }

  // old_faculty_id: old, old,

  getFacultyTransfer(faculty_id) {
    return this.http.get<any>(this.rootUrl + '/faculty/get-faculty-transfer/' + faculty_id);
  }
  //get Institution Details
  getInstitutionDetails() {
    // return this.http.get<any>(this.rootUrl + '/master/get-institute');
    return this.http.get<any>(this.rootUrl + '/master/get-institute-details');
  }

  //Update Institution Details
  updateInstitutionDetails(data) {
    return this.http.post<any>(this.rootUrl + '/institution/update-details', data);
  }

  

  

  //get all sub ledgers
  getAllSubLedgers() {
    return this.http.get<any>(this.rootUrl + '/fees/get-all-sub-ledgers');
  }

  
}
