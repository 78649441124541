<div class="header">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
  <p class="dialog-message">{{ data.message }}</p>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button class="btn " (click)="confirm()">{{ data.confirmText }}</button>
  <button class="btn btn-warning" style="margin-right:10px;" (click)="cancel()">{{ data.cancelText }}</button>

</div>