import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { DialogService } from 'app/services/dialog.service';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { Workbook } from 'exceljs';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  total_quantity: number;
  trips: any;
  selected_trip: any;
  trip_details: any;
  ind_trip: any;
  total_loaded_pkg: number;
  checklist_verification: string;
  ind_customer_data: any;

  datasource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private tripservice: TripService, private print: PrintServiceService, private dialog: DialogService, private toastr: ToastrService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getTrips();
  }

  getTrips() {
    this.tripservice.getTrips().subscribe((res: any) => {
      // console.log(res);
      this.trips = res.data;
      if (!this.selected_trip)
        this.selected_trip = this.trips[0].id;
      this.onChangeTrip();
    });
  }

  onChangeTrip() {
    // console.log(this.selected_trip);
    this.tripservice.getCheckList(this.selected_trip).subscribe((res: any) => {
      // console.log(res);
      this.trips = res.trips;
      let indtrip = this.trips.filter(element => element.id === this.selected_trip);
      this.ind_trip = indtrip[0];
      this.trip_details = res.data;
      this.total_quantity = 0;
      this.total_loaded_pkg = 0;
      var new_array = [];
      if (res.data.length > 0) {
        // console.log(this.trip_details.length);

        for (let i = 0; i < this.trip_details.length; i++) {
          this.trip_details[i].loaded_array = [];

          var loaded_pkg_list = this.trip_details[i].loaded_pkg_list.split(',');
          var waybill_list = this.trip_details[i].waybill_list.split('/');
          var all_waybills = this.trip_details[i].waybill_list.split('/');
          all_waybills.sort((a, b) => a - b);

          // console.log(loaded_pkg_list);
          // console.log(waybill_list);
          // unique start
          var unique_waybills = [];
          $.each(all_waybills, function (i, el) {
            if ($.inArray(el, unique_waybills) === -1) unique_waybills.push(el);
          });
          // console.log(unique_waybills);
          this.trip_details[i].unique_waybills = unique_waybills

          // end

          let w_splitter = 0
          let c_id = 0
          let waybills = '';
          var packages = []
          // for (let j = 0; j < waybill_list.length; j++) {
          //   c_id = waybill_list[j]
          //   if (c_id != o_id) {
          //     o_id = c_id
          //     packages.push(parseInt(loaded_pkg_list[j]))
          //     if (waybills) {
          //       waybills += '/' + c_id;
          //     } else {
          //       waybills += c_id;
          //     }
          //   } else {
          //     packages[packages.length - 1] += parseInt(loaded_pkg_list[j])
          //   }
          // }
          for (let j = 0; j < unique_waybills.length; j++) {
            c_id = unique_waybills[j]
            w_splitter++;
            if (waybills) {
              if (w_splitter > 3) {
                w_splitter = 0;
                var seperator = ' /';
              } else {
                var seperator = '/';
              }
              waybills += seperator + c_id;
            } else {
              waybills += c_id;
            }
            packages[j] = 0;
            for (let k = 0; k < waybill_list.length; k++) {
              let waybill = waybill_list[k]

              if (c_id === waybill) {
                packages[j] = packages[j] + parseInt(loaded_pkg_list[k])
              }
            }
          }

          var l = packages.length;
          // console.log("length " + l + " id " + this.trip_details[i]);

          if (l > 10) {
            var new_packages = packages.splice(10, 20)
            new_array.push({ 'index': i, 'receiver_code': this.trip_details[i].receiver_code, 'waybill_list': '', 'receiver_name': '', 'loaded_array': new_packages, 'narration': '' })
          }

          this.trip_details[i].loaded_array = packages
          this.trip_details[i].waybill_list = waybills

          this.total_quantity += +this.trip_details[i].total_quantity;
          this.total_loaded_pkg += +this.trip_details[i].total_loaded_pkg;
        }
      }

      var index;
      for (let j = 0; j < new_array.length; j++) {
        index = new_array[j].index;
        this.trip_details.splice(index + 1, 0, new_array[j])
      }

      // console.log(this.ind_trip);
      this.checklist_verification = 'Checklist verified by ' + this.ind_trip?.staff_name + '[' + this.ind_trip?.staff_code + '] on '
      var created_at = new Date(this.ind_trip.created_at + ' UTC');
      this.checklist_verification += created_at;
      // console.log(this.checklist_verification);

      this.datasource = new MatTableDataSource(this.trip_details);
      this.datasource.paginator = this.paginator
      this.datasource.sort = this.sort;

    });
  }


  // verifyCheckList() {
  //   // console.log(this.selected_trip);
  //   var dialogTitle = "Do you want to verify the checklist";
  //   var dialogText = "Are you sure ?";
  //   this.dialog.openDialogConfirm(dialogTitle, dialogText).afterClosed().subscribe(res => {

  //     if (res) {
  //       this.tripservice.verifyCheckList(this.selected_trip).subscribe((res: any) => {
  //         // console.log(res);
  //         if (res.success) {
  //           this.toastr.success(res.message, 'Success');
  //           this.getTrips();
  //         } else {
  //           this.toastr.warning(res.message, 'Warning');
  //         }
  //       });
  //     } else {
  //       // console.log("Trip not started");
  //     }
  //   })
  // }

  setIndCustomer(data) {
    // console.log(data);
    this.ind_customer_data = data;
  }

  printCheckList() {
    this.print.printSingleDocument('checklist-print', this.selected_trip);
  }

  exportCheckList() {
    var file_name = this.ind_trip.trip_no + '-' + this.ind_trip.trip_name + ' Check List';
    const filename = file_name + '.' + 'xlsx';
    let element = document.getElementById('checkList');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!rows'] = [];
    // ws['!rows'][this.datasource.data.length+1] = { hidden: false };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename);
  }

  /**
   * Excel creation
   */
  async generateExcel() {

    // console.log(this.datasource.data);
    // // console.log(this.today_date);
    // // console.log(this.today_date_h);
    // console.log(this.trip_details);

    // this.from = new Date();
    // this.today_date = this.datePipe.transform(this.from, 'dd/MM/yyyy');

    // Excel Title, Header, Data
    const title = "GOLDEN ELITE CARGO " + this.ind_trip?.trip_name + ' - ' + this.datePipe.transform(this.ind_trip?.date, 'dd-MM-yyyy');
    // const header = ['DATE', 'CONS#', 'SHIPPER', 'CONSIGNEE', 'COMMODITY', 'PKG', 'REMARKS'];
    const header = ['Sl', 'Way Bill', 'Receiver Name', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'Total', 'REMARKS'];

    var sheet_name = this.ind_trip?.trip_name + ' - ' + this.datePipe.transform(this.ind_trip?.date, 'dd-MM-yyyy');
    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(sheet_name);

    const rowValues = [];
    rowValues[1] = title;
    rowValues[14] = 'JOB NO: ' + this.ind_trip?.job_no;
    // Add Row and formatting
    const titleRaw = worksheet.addRow(rowValues);
    titleRaw.font = { name: 'Calibri', family: 4, size: 16, underline: 'none', bold: false };
    titleRaw.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    // worksheet.mergeCells('A1:O1');
    worksheet.mergeCells('A1:M1');
    worksheet.mergeCells('N1:O1');

    // Add Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Verdana', family: 4, size: 11, color: { argb: '#FF0000' }, underline: 'none', bold: true };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    var i = 1;
    this.trip_details.forEach(d => {
      const rowValues = [];
      rowValues[1] = i++;
      rowValues[2] = d.receiver_code + '/' + d.waybill_list;
      rowValues[3] = d.receiver_name;
      var ci = 0;
      d.loaded_array.forEach(cd => {
        rowValues[4 + ci] = cd;
        ci++;
      })

      rowValues[14] = parseInt(d.total_loaded_pkg);
      // rowValues[6] = d.loaded_pkg;
      // rowValues[7] = d.narration;
      const contentRow = worksheet.addRow(rowValues);
      contentRow.font = { name: 'Verdana', family: 4, size: 10, underline: 'none', bold: false };

      // Cell Style : Fill and Border
      contentRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
          // bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    const rowValues1 = [];
    var emptyRow = worksheet.addRow(rowValues1);
    emptyRow.font = { name: 'Verdana', family: 4, size: 10, underline: 'none', bold: false };
    // Cell Style : Fill and Border
    emptyRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 43;
    worksheet.getColumn(3).width = 48;
    worksheet.getColumn(4).width = 8;
    worksheet.getColumn(5).width = 8;
    worksheet.getColumn(6).width = 8;
    worksheet.getColumn(8).width = 8;
    worksheet.getColumn(9).width = 8;
    worksheet.getColumn(10).width = 8;
    worksheet.getColumn(11).width = 8;
    worksheet.getColumn(12).width = 8;
    worksheet.getColumn(13).width = 8;
    worksheet.getColumn(14).width = 8;
    worksheet.getColumn(15).width = 9;
    worksheet.getColumn(16).width = 25;


    // Footer Row
    const footeValues = [];
    // footeValues[6] = worksheet.getCell(`=SUM(F6:F${footerRow.number})`);
    const footerRow = worksheet.addRow(footeValues);

    footerRow.getCell(14).value = this.total_loaded_pkg;
    // footerRow.getCell(6).value = this.getLoadedTotal1();
    footerRow.font = { name: 'Verdana', family: 4, size: 20, underline: 'none', bold: true };
    footerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    // worksheet.getCell('B1').value = {formula : 'SUM(A1,A2)', result: (worksheet.getCell('A1').value + worksheet.getCell('A2').value) }


    // Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    var file_name = this.ind_trip.trip_no + '-' + this.ind_trip.trip_name + ' Check List';
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, file_name + '.xlsx');
    });
  }

  onSortCheckList(sort: Sort) {
    const data = this.trip_details.slice();
    if (!sort.active || sort.direction === '') {
      this.trip_details = data;
      return;
    }

    this.trip_details = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'receiver_name': return compare(a.receiver_name, b.receiver_name, isAsc);
        default: return 0;
      }
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
