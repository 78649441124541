<div class="main-content">
  <div class="container-fluid">
    <div class="card mt-0">
      <div class="card-body" style="background-color: lightseagreen;">
        <div class="row">
          <div class="col-12 ">
            <mat-form-field class="example-full-width" appearance="outline" style="background-color: white;">
              <input matInput placeholder="Way Bill" [(ngModel)]="wayBillNo"
                (keyup.enter)="searchIndWayBIll(wayBillNo)">
              <button mat-icon-button (click)="searchIndWayBIll(wayBillNo)" color="primary" matSuffix> <i
                  class="material-icons">search</i> </button>
            </mat-form-field>
          </div>
        </div>

        <mat-tab-group class="bg-color" dynamicHeight *ngIf="way_bill">

          <mat-tab label="Details">
            <ng-container>
              <div class="container" *ngIf="show_way_bill">

                <div class="card-header" style="background-color: lightseagreen;">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 padding">
                      <p class="card-title text-left margin" style="font-weight: bold;">Way Bill
                        No:&nbsp;{{way_bill?.waybill_no}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 padding">
                      <p class="card-title float-right margin" style="font-weight: bold;">Way Bill
                        Date:&nbsp;{{way_bill?.waybill_date | date:"dd-MM-yyyy"}}</p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 col-sm-6 col-md-6 padding">
                    <h4 class="alignstyle"><strong> SENDER </strong></h4>
                    <h5> Name : {{way_bill?.sender_name}}</h5>
                    <h5> Code : {{way_bill?.sender_code}}</h5>
                    <h5> Address : {{way_bill?.sender_address}}</h5>
                    <h5> Mobile : {{way_bill?.sender_mobile}}</h5>
                    <h5> Email : {{way_bill?.sender_email}}</h5>
                    <h5> Country : {{way_bill?.sender_country_name}}</h5>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6">
                    <h4 class="alignstyle"><strong> RECEIVER </strong></h4>
                    <h5> Name : {{way_bill?.receiver_name}}</h5>
                    <h5> Code : {{way_bill?.receiver_code}}</h5>
                    <h5> Address : {{way_bill?.receiver_address}}</h5>
                    <h5> Mobile : {{way_bill?.receiver_mobile}}</h5>
                    <h5> Email : {{way_bill?.receiver_email}}</h5>
                    <h5> Country : {{way_bill?.receiver_country_name}}</h5>
                  </div>
                </div>
              </div>
              <div class="container new" *ngIf="show_way_bill">
                <div class="row headColor justify-content-center pt-3 mt-2" style="color: white;">
                  <h4><b>Description of contents</b></h4>
                </div>
                <div class="row c-head ridge_border text-light">
                  <div class="col-1 col-sm-1 col-md-1 col-lg-1 padding">
                    <h4 style="white-space: nowrap;"><strong>No</strong></h4>
                  </div>
                  <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                    <h4 style="white-space: nowrap;"><strong>Commodities</strong></h4>
                  </div>
                  <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                    <h4 style="white-space: nowrap;"><strong>Package</strong></h4>
                  </div>
                  <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                    <h4 style="white-space: nowrap;"><strong>Origin</strong></h4>
                  </div>
                </div>
                <ng-container>
                  <div class="row c-body ridge_border pt-1" *ngFor="let com of show_commodities;index as i">
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 padding">
                      {{i+1}}
                    </div>
                    <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                      {{com?.item_name}}
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                      {{com?.quantity}} {{com.item_unit}}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                      {{com?.country_name}}
                    </div>
                  </div>
                </ng-container>

                <div class="row mt-2">
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 ">
                    <h4 class="text-center"><strong> ORIGIN </strong></h4>
                    <h5 class="text-center"> {{way_bill?.origin}} </h5>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 ">
                    <h4 class="text-center"><strong> DESTINATION </strong></h4>
                    <h5 class="text-center"> {{way_bill?.destination}} </h5>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-sm-12 col-md-6 " style="padding-left: 10px;">
                    <h5>Shipment mode:
                      {{way_bill?.shipment_mode==1 ? 'BY ROAD': way_bill?.shipment_mode==2 ? 'BY SEA':
                      way_bill?.shipment_mode==3 ? 'BY AIR' : 'Clearing'}}
                    </h5>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    <h5>Measurement</h5>
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    Length: {{way_bill?.measurement_l}}
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    Width: {{way_bill?.measurement_w}}
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    Height: {{way_bill?.measurement_h}}
                  </div>
                </div>
                <div class="row">


                  <div class="col-3">
                    <h5 style="white-space: nowrap;">Volume</h5>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 ">
                    Length X Width X Height =
                    {{way_bill?.measurement_l*way_bill?.measurement_w*way_bill?.measurement_h}}
                    CM<sup>3</sup>
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3">
                    Weight: {{way_bill?.weight}}
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <h5>Narration: {{way_bill?.narration}}</h5>
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              <button mat-icon-button type="button">
                <mat-icon>edit</mat-icon>
              </button>
            </ng-template>

            <form [formGroup]="editWayBillForm">
              <div class="container mt-3">
                <div class="row justify-content-center" style="background-color: lightseagreen;">
                  <div class="col-12 col-sm-12 col-md-6 padding">
                    <mat-form-field style="padding-right: 15px;" appearance="outline">
                      <input matInput type="number" placeholder="Way Bill No" formControlName="waybill_no"
                        #measurement_h>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 padding">

                    <mat-form-field color="accent" style="margin-right: 70px;" style="font-weight: bold;"
                      appearance="outline">
                      <input matInput [matDatepicker]="picker2" placeholder="Way bill date"
                        formControlName="waybill_date">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <!-- start dropdown serch -->
                <div class="row mt-3">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 padding">
                    <mat-form-field appearance="outline">
                      <mat-label>Sender Details</mat-label>
                      <input matInput type="text" placeholder="Sender Details" (keyup)="searchSenders($event)"
                        [matAutocomplete]="auto" formControlName="sender_id" #sender_id>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="getSenderDetails($event.option.value)">
                        <mat-option *ngFor="let sender of sender_list" [value]="sender">
                          {{sender?.customer_name}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 padding">
                    <mat-form-field appearance="outline">
                      <mat-label>Enter Receiver Details</mat-label>
                      <input matInput type="text" placeholder="Receiver Details" (keyup)="searchReceivers($event)"
                        [matAutocomplete]="auto1" formControlName="receiver_id" #receiver_id>
                      <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn1"
                        (optionSelected)="getReceiverDetails($event.option.value)">
                        <mat-option *ngFor="let receiver of receiver_list" [value]="receiver">
                          {{receiver?.customer_name}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <!-- end dropdown serch -->

                <div class="container p-3" formArrayName="commodities">
                  <div class="row headColor justify-content-center pt-3 mt-2" style="color: white;">
                    <h4><b>Description of contents</b></h4>
                  </div>
                  <div class="row text-light c-head ridge_border pt-3 pt-1">

                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 padding">
                      <h4 style="white-space: nowrap;">No</h4>
                    </div>
                    <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                      <h4 style="white-space: nowrap;">Commodities</h4>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                      <h4 style="white-space: nowrap;">Package</h4>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                      <h4 style="white-space: nowrap;">Origin</h4>
                    </div>
                  </div>
                  <div class="row c-body pt-1" *ngFor="let one of commoditiesArray.controls; index as i;last as last"
                    [formGroupName]="i">
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 padding">
                      {{i+1}}
                    </div>

                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                      <mat-form-field appearance="outline" class="background">
                        <mat-label>Enter Commodity</mat-label>
                        <input matInput type="text" placeholder="Commodity" (keyup)="searchItem($event)"
                          [matAutocomplete]="auto2" formControlName="item_id" #item_id>
                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2"
                          (optionSelected)="getCommodityDetails($event.option.value,i)">
                          <mat-option *ngFor="let item of item_list" [value]="item">
                            {{item?.item_name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>

                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                      <div class="half-width same-line">
                        <mat-form-field appearance="outline">
                          <input matInput type="number" min="{{commoditiesArray.value[i].loaded}}"
                            placeholder="No of Packages" formControlName="quantity" #quantity>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                      <div class="half-width same-line">
                        <mat-form-field appearance="outline">
                          <mat-label>Origin Country</mat-label>
                          <input matInput type="text" placeholder="Origin Country" (keyup)="searchItemCountry($event)"
                            [matAutocomplete]="auto5" formControlName="item_country" #item_country>
                          <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFn5"
                            (optionSelected)="getCountryDetails($event.option.value)">
                            <mat-option *ngFor="let country of country_list" [value]="country">
                              {{country?.country_name}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                      <div class="same-line" *ngIf="last">
                        <button type="button" mat-icon-button color="primary"
                          aria-label="Example icon-button with a add icon" title="{{ saveCommandTitleA }}"
                          (click)="onAddNewRow()">
                          <mat-icon>add</mat-icon> Add new row ({{ saveCommandTitleA }})
                        </button>
                      </div>
                    </div>
                    <div class="same-line" *ngIf="i!=0">
                      <button type="button" mat-icon-button color="warn"
                        aria-label="Example icon-button with a delete icon" title="{{ saveCommandTitleB }}"
                        (click)="onRemoveRow(i)">
                        <mat-icon>delete</mat-icon>
                        <!-- ({{ saveCommandTitleB }}) -->
                      </button>
                    </div>
                    <div class=" col-12 col-sm-12 col-md-12 col-lg-12" *ngIf="commoditiesArray?.value[i].loaded">
                      <h5 class="text-success" style="padding-left: 31px;">[{{commoditiesArray?.value[i].loaded}}
                        Packages added to trip/return]</h5>
                    </div>
                  </div>
                </div>

                <div class="container" style="background-color: #c3dee6;">
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                      <mat-form-field appearance="outline">
                        <mat-select placeholder="Origin" formControlName="origin_check_id" #origin_check_id>
                          <mat-option *ngFor="let check of check_points" [value]="check?.id">
                            {{check?.check_point}}
                          </mat-option>
                        </mat-select>

                      </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                      <mat-form-field appearance="outline" style="padding-left: 10px; padding-right: 5px;">
                        <mat-select placeholder="Destination" formControlName="destination_check_id"
                          #destination_check_id>
                          <mat-option *ngFor="let check of check_points" [value]="check?.id">
                            {{check?.check_point}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <h5 class="mr-2">Shipment mode</h5>
                    <mat-radio-group formControlName="shipment_mode" #shipment_mode>
                      <mat-radio-button [tabindex]="1" class="questions-radio-button" [value]="1">
                        By Road
                      </mat-radio-button>
                      <mat-radio-button [tabindex]="2" class="questions-radio-button" [value]="2">
                        By Sea
                      </mat-radio-button>
                      <mat-radio-button [tabindex]="3" class="questions-radio-button" [value]="3">
                        By Air
                      </mat-radio-button>
                      <mat-radio-button [tabindex]="4" class="questions-radio-button same-line" [value]="4">
                        Clearing
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <mat-form-field style="padding-right: 15px;" appearance="outline">
                        <input matInput type="number" placeholder="Length" formControlName="measurement_l"
                          #measurement_l>
                        <span matSuffix>CM</span>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <mat-form-field style="padding-right: 15px;" appearance="outline">
                        <input matInput type="number" placeholder="Width" formControlName="measurement_w"
                          #measurement_w>
                        <span matSuffix>CM</span>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <mat-form-field style="padding-right: 15px;" appearance="outline">
                        <input matInput type="number" placeholder="Height" formControlName="measurement_h"
                          #measurement_h>
                        <span matSuffix>CM</span>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <mat-form-field appearance="outline">
                        <input matInput type="number" placeholder="Enter Weight" formControlName="weight" #weight>
                        <span matSuffix>KG</span>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <mat-form-field appearance="outline">
                        <textarea matInput placeholder="Enter Narration" formControlName="narration"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <button mat-flat-button class=" btn-success  ml-3 mb-2" (click)="updateWaybillDetails()"
                  title="{{ saveCommandTitle }}">UPDATE ({{ saveCommandTitle }})</button>
              </div>
            </form>

          </mat-tab>

          <mat-tab disabled>
            <ng-template mat-tab-label>
              <button mat-icon-button type="button" (click)="rePrintWayBill()">
                <mat-icon>print</mat-icon>
              </button>
            </ng-template>
          </mat-tab>

        </mat-tab-group>
        <div class="row justify-content-center" *ngIf="!way_bill">
          <h4 class="text-danger text-center">NO RECORDS AVAILABLE</h4>
        </div>
      </div>
    </div>
  </div>
</div>