<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <div class="row mt-3">
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <img class="image" src="../../../assets/img/invoice-1.jpg">
                        </div>

                        <div class="col-sm-8 col-md-8 col-lg-8 mt-5">
                            <h6>P.O Box 1869, Manama Kingdom of Bahrain Tel.+97317483473 Mob
                                +97335302143 goldenelitebah@gmail.com</h6>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-4" style="margin-top: 11px;">
                            <div class="row">
                                <div class=" col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>BD</mat-label>
                                        <input matInput type="number" min="1" placeholder="BD" [(ngModel)]="voucher_bd"
                                            required>
                                    </mat-form-field>
                                </div>

                                <div class=" col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fils</mat-label>
                                        <input matInput type="number" min="1" placeholder="Fils"
                                            [(ngModel)]="voucher_fils" required>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4" style="margin-top: -20px;">
                            <h5 style="text-align: center;"><u><b>قسيمة دفع</b></u><br><b>PAYMENT VOUCHER</b></h5>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4" style="margin-top: -10px;">
                            <p>No.</p>
                            <div>
                                <mat-form-field color="accent" style="margin-right: 70px;" style="font-weight: bold;">
                                    <input matInput [matDatepicker]="picker2" placeholder="Way bill date"
                                        [(ngModel)]="voucher_date">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- </div>
    </div> -->

                    <div class="row">
                        <div class="col-12">

                            <form [formGroup]="addVoucherForm">
                                <div class="container">

                                    <div class="row justify-content-center">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <mat-form-field>
                                                <input matInput placeholder="Paid to" formControlName="paid_to">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <mat-form-field>
                                                <input matInput placeholder="The Sum of BD" formControlName="sum_bd">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Cash / Cheque No" formControlName="cash">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Date" formControlName="date">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <mat-form-field>
                                                <input matInput placeholder="Bank" formControlName="bank">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <mat-form-field>
                                                <input matInput placeholder="Towards" formControlName="towards">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <mat-form-field>
                                                <input matInput placeholder="Manager" formControlName="manager">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <mat-form-field>
                                                <input matInput placeholder="Accountant" formControlName="accountant">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <mat-form-field>
                                                <input matInput placeholder="Recieved By" formControlName="received_by">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-success" (click)="createVoucher()">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>