<nav class="navbar navbar-expand-lg fixed-top bg-logistics  navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <!-- <mat-icon class="window-back" (click)="onClickExpand()">dehaze</mat-icon> -->
            <!-- <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a> -->
            <mat-icon *ngIf="isMobileMenu()" class="window-back" (click)="goBack()">arrow_back</mat-icon>
            <!-- <div class="toggler">

                <button mat-icon-button>
                    <mat-icon *ngIf="!isMobileMenu()" class="window-back text-white" (click)="toggle()">
                        menu</mat-icon>
                </button>
            </div> -->
            <div class="row">
                <div class="toggler">
                    <button mat-icon-button>
                        <mat-icon *ngIf="!isMobileMenu()" class="window-back text-white" (click)="toggle()">
                            menu</mat-icon>
                    </button>
                </div>
                <div class="toggler">
                    <button mat-icon-button data-toggle="modal" data-target="#menuModal">
                        <mat-icon *ngIf="true" class="window-back text-white" (click)="openQuickAccessMenu()">
                            view_list</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>

        <!-- <script>
            function goBack() {
              window.history.back();
            }
            </script> -->


        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <!-- <form class="navbar-form">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form> -->
            <ul class="navbar-nav">
                <!-- <li class="nav-item dropdown">
                    <button type="button" mat-icon-button class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <mat-icon>apps</mat-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="let data of quick_menus">
                                <div class="text-center">
                                    <mat-icon> {{data?.sub_menu_icon_class}} </mat-icon>
                                </div>
                                <span style="font-size: 8px; text-align: center;"> {{data?.sub_menu_name}} </span>
                            </div>
                        </div>
                    </div>
                </li> -->
                <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                        <i class="material-icons">dashboard</i>
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li> -->
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{username}}<i class="material-icons">account_box</i>
                        
                        
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()" >Log Out <i class="material-icons">lock</i></a>
                        
                    </div>
                </li>  -->
                <!-- <i style="color: black;" class="material-icons">face</i> -->
                <li class="nav-item">
                    <a class="nav-link profile-photo" href="javascript:void(0)" id="navbarDropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{user?.name}}
                        <!-- <img src='./assets/img/faces/marc.png' class="profile-photo-small"/> -->
                        <img src="{{s3url}}/profiles/{{user?.user_id}}.jpg"
                            onError="this.src='./assets/img/faces/marc.png';" class="profile-photo-small" />


                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"
                                style="font-size: 10px;"><i class="material-icons">lock</i> Log Out </a>

                            <a class="dropdown-item" href="javascript:void(0)" routerLink="/my-account"
                                style="font-size: 10px;"><i class="material-icons">person</i> MY ACCOUNT </a>

                            <!-- <li routerLinkActive="active" class="nav-item">
                                        <a class="nav-link" routerLink="/test">
                                            <i class="material-icons">person</i>TEST
                                            <p>TEST</p>
                                        </a>
                                    </li> -->

                            <!-- <a class="dropdown-item" href="javascript:void(0)" routerLink="/trip/return-shipment"><i
                                    class="material-icons">person</i> TEST </a> -->


                        </div>
                        <!-- <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p> -->
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<nav class="navbar navbar-expand-lg fixed-top navbar-absolute" *ngIf="!isConnected">
    <div class="col-12 bg-danger text-white text-center">No internet</div>
</nav>

<!-- start -->
<div class="modal fade modal-smallscreen" id="menuModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">

    <div class="modal-dialog" role="document">

        <div class="modal-content">
            <div class="nav-item text-center" routerLinkActive="active" *ngFor="let menu of quick_menus">
                <a routerLink="/{{menu.sub_menu_url}}" data-dismiss="modal">
                    <i class="material-icons border rounded quick_view">{{menu?.sub_menu_icon_class}}</i>
                    <small style="font-size: 50%; white-space: nowrap; color: black">{{menu?.sub_menu_name |
                        uppercase}}</small>
                </a>
            </div>
            <div class="nav-item text-center" routerLinkActive="active">
                <a data-dismiss="modal" data-toggle="modal" data-target="#menuAddModal">
                    <i class="material-icons border rounded quick_view">add</i>
                    <small style="font-size: 50%; white-space: nowrap; color: black">Edit</small>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="menuAddModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">

    <div class="modal-dialog" role="document">

        <div class="modal-content">
            <p><i class="material-icons border rounded  pull-right" style="color: red;" data-dismiss="modal">close</i>
            </p>

            <div class="nav-item text-center" routerLinkActive="active" *ngFor="let menuItem of new_qa_menu">
                <p>{{menuItem?.menuname}}</p>
                <div class="col-4 nav-item text-center" routerLinkActive="active" *ngFor="let menu of menuItem?.childs">
                    <!-- <div class="col-4"> -->
                    <i class="material-icons border rounded quick_view">{{menu?.sub_menu_icon_class}}
                        <!-- <button type='button' class='btn btn-primary'>Button Text</button> -->
                        <i class="material-icons border rounded  pull-right" style="color: green;"
                            (click)="updateMenu(menu, 1)">add</i>
                    </i>
                    <small style="font-size: 50%; white-space: nowrap; color: black">{{menu?.sub_menu_name |
                        uppercase}}</small>
                    <!-- </div> -->
                </div>
            </div>
            <div class="nav-item text-center" routerLinkActive="active" *ngFor="let menuItem of added_qa_menu">
                <p>{{menuItem?.menuname}}</p>
                <div class="nav-item text-center" routerLinkActive="active" *ngFor="let menu of menuItem?.childs">
                    <div class="col-4">
                        <i class="material-icons border rounded quick_view">{{menu?.sub_menu_icon_class}}
                            <!-- <button type='button' class='btn btn-primary'>Button Text</button> -->
                            <i class="material-icons border rounded  pull-right" style="color: red;"
                                (click)="updateMenu(menu, 0)">close</i>
                        </i>
                        <small style="font-size: 50%; white-space: nowrap; color: black">{{menu?.sub_menu_name |
                            uppercase}}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->