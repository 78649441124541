<div class="wrapper">
    <div class="sidebar" id="sidebar" data-color="purple" data-background-color="black"
        data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
    </div>
    <div class="main-panel" id="main-panel">
        <app-navbar></app-navbar>
        <app-loader></app-loader>
        <router-outlet></router-outlet>
    </div>
    <!-- <div class="fixed-plugin">
        <div class="dropdown show-dropdown open show" style="padding: 5px;">
            <a href="#" class="quick-menu" data-toggle="dropdown" aria-expanded="true">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-double-left" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    <path fill-rule="evenodd"
                        d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                </svg>
            </a>
            <div class="dropdown-menu">
                <div class="dropdown-item quick-menu-items logo d-block mt-1 mb-1" *ngFor="let data of quick_menus" (click)="routeToComponent(data)"  matTooltip="{{data.sub_menu_name}}">
                    <div class="text-center">
                        <mat-icon> {{data?.sub_menu_icon_class}} </mat-icon>
                    </div>
                    <span style="font-size: 8px; text-align: center;"> {{data?.sub_menu_name}} </span>
                </div>
                 <div class="dropdown-item quick-menu-items d-block logo mb-1">
                    <div class="text-center" data-toggle="dropdown" data-taget="all_menus">
                        <mat-icon> add </mat-icon>
                    </div>
                    <span class="text-center"> &nbsp;&nbsp;Edit </span>
                </div> -->
                <!-- <div class="dropdown-menu" id="all_menus" style="width: fit-content;">
                    <div class="dropdown-item quick-menu-items logo d-block mt-1 mb-1" *ngFor="let data of quick_menus">
                        <div class="text-center">
                            <mat-icon> {{data?.sub_menu_icon_class}} </mat-icon>
                        </div>
                        <span> {{data?.sub_menu_name}} </span>
                    </div> 
                </div> -->
            <!-- </div> -->
        <!-- </div> -->
    <!-- </div>  --> 
</div>
<!-- Buy-Modal-angular -->
<!-- <div class="modal modal-angular fade" id="buy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-body text-center">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="margin-top">
                Free Version
            </h4>
            <div class="separator"></div>
            <a href="https://www.creative-tim.com/product/material-dashboard" class="modal-button" target="_blank">
                <div class="wrapper-card">
                    <div class="image-container">
                        <img src="./assets/img/html.png" alt="unloaded">
                    </div>
                    Html5
                    <div class="separator"></div>
                    <div class="product-type">
                        FREE
                    </div>
                </div>
            </a>
            <a href="https://www.creative-tim.com/product/material-dashboard-angular2" class="modal-button" target="_blank">
                <div class="wrapper-card">
                    <div class="image-container image-angular-cli">
                        <img src="./assets/img/angular.png" alt="unloaded">
                    </div>
                    Angular
                    <div class="separator"></div>
                    <div class="product-type">
                        FREE
                    </div>
                </div>
            </a>
            <h4>
                PRO Version
            </h4>
            <div class="separator"></div>
            <a href="https://www.creative-tim.com/product/material-dashboard-pro" class="modal-button" target="_blank">
                <div class="image-container">
                    <img src="./assets/img/html.png" alt="unloaded">
                </div>
                Html5
                <div class="separator"></div>
                <div class="price">
                    from
                    <span>
                        49
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <a href="https://www.creative-tim.com/product/material-dashboard-pro-angular2" class="modal-button" target="_blank">
                <div class="image-container image-angular-cli">
                    <img src="./assets/img/angular.png" alt="unloaded">
                </div>
                Angular
                <div class="separator"></div>
                <div class="price">
                    from
                    <span>
                        59
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
        </div>
    </div>
</div>

</div> -->