<div class="card mt-0">
  <div class="card-body">

    <div class="row">
      <div class=" col-sm-12 col-md-3 col-lg-3">
        <!-- <mat-form-field appearance="outline">
          <mat-label>Choose Waybill date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="current_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->

        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="current_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- <p *ngIf="current_date">
          Your choice: {{current_date | date: 'dd/MM/y H:mm:ss'}}
        </p> -->
      </div>

      <div class=" col-sm-12 col-md-3 col-lg-3 butn">
        <button class="mt-2" type="button" mat-raised-button class="btn-success" style="height: 32px; margin-top: -4px;"
          (click)="getDaily(current_date)" title="{{ saveCommandTitle }}">Submit ({{ saveCommandTitle }})</button>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-3" style=" padding-top: 9px;" *ngIf="datasourceDaily?.data.length > 0">
        <button mat-button color="primary" (click)="generateExcel()">
          <mat-icon>get_app</mat-icon>Export
        </button>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-3" style=" padding-top: 9px;">
        <button mat-icon-button color="primary" (click)="showControl()" *ngIf="show_full">
          <span class="material-icons">
            expand_less
          </span> Short view
        </button>
        <button mat-icon-button color="primary" (click)="showControl()" *ngIf="!show_full">
          <span class="material-icons">
            expand_more
          </span> Detailed view
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <div class="table-responsive">

          <table mat-table [dataSource]="datasourceDaily" #sort1="matSort" matSort #table1
            class="mat-elevation-z8 table table-bordered table-sm table-striped" id="dailyTable">
            <ng-container matColumnDef="index" class="table">
              <th style="background-color: lightseagreen;  padding-right:15px" mat-header-cell *matHeaderCellDef
                class="text-light text-center"> Sl </th>
              <td mat-cell *matCellDef="let element;index as i">{{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i
                + 1) }}</td>
              <!-- {{i+1}}  -->
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="waybill_no">
              <th style="background-color: lightseagreen;  padding-left:15px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light text-center"> Way Bill
              </th>
              <td mat-cell *matCellDef="let element" class="text-left pl-2">
                {{element?.receiver_code?element?.receiver_code +'/':'/' | uppercase}}{{element?.waybill_no}}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="sender_name">
              <th style=" background-color: lightseagreen;" mat-header-cell *matHeaderCellDef mat-sort-header
                class="text-light pl-5"> Sender </th>
              <td mat-cell *matCellDef="let element" class=" min  pl-2" style="text-align:left">
                {{element?.sender_name | uppercase}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="receiver_name">
              <th style="background-color: lightseagreen;  padding-left:15px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Receiver </th>
              <td mat-cell *matCellDef="let element" class=" min  pl-2" style="text-align: left;">
                {{element?.receiver_name | uppercase}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="item_name">
              <th style="background-color: lightseagreen;  padding-left:15px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Commodity </th>
              <td mat-cell *matCellDef="let element" class=" min pl-2" style="text-align: left;">
                {{element?.item_name | uppercase}} </td>
              <td class="text-right" style="padding-right: 10px;" mat-footer-cell *matFooterCellDef><b> Total </b></td>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <th style="background-color: lightseagreen;  padding-left:15px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light col-amt"> PKG </th>
              <td mat-cell *matCellDef="let element" class="text-right" style="padding-right: 10px;">
                {{element?.quantity}} </td>
              <td mat-footer-cell *matFooterCellDef class="text-right" style="padding-right: 10px;"><b>
                  {{quantity_total}}
                </b></td>
            </ng-container>

            <ng-container matColumnDef="narration">
              <th style="background-color: lightseagreen;  padding-left:15px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Narration </th>
              <td mat-cell *matCellDef="let element" class="min" style="text-align: left; padding-left: 5px;">
                {{element?.narration ? element.narration : '--'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <tr mat-header-row class="headColor" *matHeaderRowDef="displayedWayBillsDaily"></tr>
            <tr mat-row class="bodyColor" *matRowDef="let row; columns: displayedWayBillsDaily;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedWayBillsDaily"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons #paginatorRef></mat-paginator>
          <ng-container *ngIf="!datasourceDaily || datasourceDaily?.data.length == 0">
            <h4 class="text-danger text-center">NO RECORDS AVAILABLE</h4>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</div>