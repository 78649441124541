<div class="card mt-0">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="search-box-container">
          <button class="search-icon" mat-icon-button (click)="searchByNumber(searchValue)">
            <mat-icon>search</mat-icon>
          </button>
          <input type="number" [value]="way_bill_number ? way_bill_number: ''"
            (keydown.enter)="searchByNumber(searchValue)" placeholder="Way Bill Number" [(ngModel)]="searchValue">
        </div>
      </div>
      
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="search-box-container-1">
          <button class="search-icon" mat-icon-button (click)="searchByCustomer(searchNameValue)">
            <mat-icon>search</mat-icon>
          </button>
          <input matInput [value]="way_bill_customer ? way_bill_customer: ''"
            (keydown.enter)="searchByCustomer(searchNameValue)" placeholder="Customer Details"
            [(ngModel)]="searchNameValue">
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate matInput placeholder="From" [(ngModel)]="from">
            <input matEndDate matInput placeholder="To" [(ngModel)]="to">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
        <button type="submit" mat-raised-button class="btn-success" title="{{ saveCommandTitle }}"
          (click)="onSubmitDate()">Submit ({{ saveCommandTitle }}) </button>
      </div>

      <div class="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 text-center" style="padding: 0px;">
        <button mat-button color="primary" (click)="generateExcel()" *ngIf="way_bills?.length > 0">
          <mat-icon>get_app</mat-icon>Export
        </button>
      </div>

      <div class="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center" style="padding: 0px;">
        <button mat-icon-button color="primary" (click)="printWayBill()" *ngIf="way_bills?.length > 0">
          <mat-icon>print</mat-icon>Print
        </button>
      </div>

      <div class="col-4 col-sm-12 col-md-6 col-lg-3 col-xl-3" style="padding: 0px;">
        <button mat-icon-button class="float-left" color="primary" (click)="showControl()" *ngIf="show_full">
          <span class="material-icons">
            expand_less
          </span> Short view
        </button>
        <button mat-icon-button color="primary" (click)="showControl()" *ngIf="!show_full">
          <span class="material-icons">
            expand_more
          </span> Detailed view
        </button>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive pl-0 pr-0">
          <table mat-table [dataSource]="datasource" matSort
            class="mat-elevation-z8 table table-bordered table-sm table-striped" id="wayBillTable">
            <ng-container matColumnDef="index" class="table">
              <th style="background-color: lightseagreen; padding-right:10px; padding-left: 10px;" mat-header-cell
                *matHeaderCellDef class="text-light text-center"> Sl </th>
              <td mat-cell *matCellDef="let element;index as i">{{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i
                + 1) }} </td>
              <!-- {{i+1}}  -->
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="waybill_date">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light text-center"> Date </th>
              <td mat-cell *matCellDef="let element" class="nowrap"> {{element?.waybill_date | date:'dd-MM-yyyy'}}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="waybill_no">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light text-center nowrap"> Way Bill
              </th>
              <td mat-cell *matCellDef="let element" class="text-left nowrap pl-2">
                {{element?.receiver_code?element?.receiver_code+'/':'/'}} {{element?.waybill_no}}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="sender_name">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Sender </th>
              <td mat-cell *matCellDef="let element" class=" min pl-2" style="text-align:left"> {{element?.sender_name}}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="receiver_name">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Receiver </th>
              <td mat-cell *matCellDef="let element" class=" min pl-2 pr-3" style="text-align:left">
                {{element?.receiver_name}}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="item_name">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Commodity </th>
              <td mat-cell *matCellDef="let element" class="min pl-2" style="text-align:left"> {{element?.item_name}}
              </td>
              <td style="text-align: center;" mat-footer-cell *matFooterCellDef><b> Total </b></td>
            </ng-container>
            <ng-container matColumnDef="loaded_pkg">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Loaded </th>
              <td mat-cell *matCellDef="let element" class="text-right" style="padding-right: 10px;">
                {{element?.loaded_pkg}} </td>
              <td mat-footer-cell *matFooterCellDef class="text-right" style="padding-right: 10px;"><b>
                  {{loaded_total}}
                </b></td>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <th style="background-color: lightseagreen;" mat-header-cell *matHeaderCellDef mat-sort-header
                class="text-light"> PKG </th>
              <td mat-cell *matCellDef="let element" class="text-right" style="padding-right: 10px;">
                {{element?.quantity}} </td>
              <td mat-footer-cell *matFooterCellDef class="text-right" style="padding-right: 10px;"><b>
                  {{quantity_total}}
                </b></td>
            </ng-container>
            <ng-container matColumnDef="trip_name">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Trip </th>
              <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left">
                {{element?.trip_name ? element.trip_name : '--'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="check_point">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light nowrap"> Trip Position </th>
              <td mat-cell *matCellDef="let element" class=" min pl-2" style="text-align:left">
                {{element?.check_point ? element.check_point : '--'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="tracking_position_status">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Status </th>
              <td mat-cell *matCellDef="let element" class="pl-2 text-left">
                {{element?.tracking_position_status ? element.tracking_position_status : 'Packed'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="narration">
              <th style="background-color: lightseagreen; padding-left:10px" mat-header-cell *matHeaderCellDef
                mat-sort-header class="text-light"> Narration </th>
              <td mat-cell *matCellDef="let element" class=" min pl-2" style="text-align:left">
                {{element?.narration ? element.narration : '--'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th style="background-color: lightseagreen;  padding-left:15px" mat-header-cell *matHeaderCellDef
                class="text-light"> Action </th>
              <td mat-cell *matCellDef="let element">
                <button class="text-center" color="primary" mat-icon-button
                  aria-label="Example icon-button with a edit icon" (click)="onSelectWayBill(element)"
                  data-toggle="modal" data-target="#WayTripBillModal">
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <tr mat-header-row class="headColor" *matHeaderRowDef="displayedWayBills"></tr>
            <tr mat-row class="bodyColor" *matRowDef="let row; columns: displayedWayBills;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedWayBills"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons #paginatorRef></mat-paginator>

          <ng-container *ngIf="!datasource || datasource?.data.length == 0">
            <h4 class="text-danger text-center">NO RECORDS AVAILABLE</h4>
          </ng-container>
        </div>
      </div>
    </div>