<h2 mat-dialog-title>Details</h2>
<div mat-dialog-content>

  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="headColor text-light">
              <th class="text-center">Date</th>
              <th class="text-center">Way Bill</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Commodity</th>
              <th>PKG</th>
              <th>Trip</th>
              <th>Position</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody class="bodyColor">
            <tr>
              <td class="nowrap">{{waybill?.waybill_date | date:'dd-MM-yyyy'}}</td>
              <td>{{waybill?.waybill_no}}</td>
              <td>{{waybill?.sender_name}}</td> 
              <td>{{waybill?.receiver_name}}</td>
              <td class="nowrap">{{waybill?.item_name}}</td>
              <td>{{waybill?.quantity}}</td>
              <td class="nowrap">{{waybill?.trip_name}}</td>
              <td class="nowrap">{{waybill?.check_point}}</td>
              <td>{{waybill?.tracking_position_status}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="container bodyColor1" *ngIf="trip_exist">
    <div class="row">
      <div *ngFor="let trip_move of trip_movement ; index as i ; first as first ; last as last">
        <div class="mr-4 ml-4" *ngIf="trip_move?.tracking_position == null">
          <div>
            <h5>{{origin?.origin}}
              <span>
                <mat-icon class="text-dark ml-2 mt-3" style="vertical-align: bottom"
                  *ngIf="trip_move?.end_check_id != trip_move?.check_point_id">forward
                </mat-icon>
              </span>
            </h5>
          </div>


        </div>
        <div class="mr-4 ml-4" *ngIf="trip_move?.tracking_position != null">
          <h5 class="nowrap" matTooltip="{{trip_move?.tracking_date | date:'dd-MM-yyyy'}} {{trip_move?.tracking_time}}"
            matTooltipClass="trip-movement-tooltip" aria-label="h5 that shows a red tooltip">
            {{trip_move?.check_point}}
            <span>
              <mat-icon class="text-dark ml-2 mt-3" style="vertical-align: bottom"
                *ngIf="trip_move?.end_check_id != trip_move?.check_point_id">forward
              </mat-icon>
            </span>
          </h5>
        </div>
        <div *ngIf="last" class="mr-4 ml-4 mb-3">
          <mat-icon class="text-primary">local_shipping
          </mat-icon>
        </div>
      </div>
      <div class="ml-auto mr-4 mt-3" *ngIf="!status">
        <div>
          <h5>{{destination?.destination}}</h5>
        </div>
      </div>
    </div>
  </div>

</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-danger">Cancel</button>
</mat-dialog-actions>