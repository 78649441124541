import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaybillRoutingModule } from './waybill-routing.module';
import { MaterialModule } from 'app/material.module';
import { ConfirmDialogModule } from 'app/_shared/confirm-dialog/confirm-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WayBillComponentComponent } from './way-bill-component/way-bill-component.component';
import { WaybillEditComponentComponent } from './waybill-edit-component/waybill-edit-component.component';
import { DailyReceivedShipmentComponentComponent } from './daily-received-shipment-component/daily-received-shipment-component.component';
import { WaybillReportComponentComponent } from './waybill-report-component/waybill-report-component.component';
import { CreateWayBillComponentComponent } from './create-way-bill-component/create-way-bill-component.component';
import { WayBillmodalComponentComponent } from './way-billmodal-component/way-billmodal-component.component';
import { WaybillVeiwComponentComponent } from './waybill-veiw-component/waybill-veiw-component.component';
import { ConsignmentComponent } from './consignment/consignment.component';
import { ManifestoViewComponent } from '../trip/manifesto-view/manifesto-view.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { WaybillCreateComponent } from './waybill-create/waybill-create.component';
import { WaybillRateCalculationComponent } from './waybill-rate-calculation/waybill-rate-calculation.component';
// import { DailyReceivedShipmentComponent } from '../trip/daily-received-shipment/daily-received-shipment.component';


@NgModule({
  declarations: [
    WayBillComponentComponent,
    WaybillEditComponentComponent,
    DailyReceivedShipmentComponentComponent,
    WaybillReportComponentComponent,
    CreateWayBillComponentComponent,
    WayBillmodalComponentComponent,
    WaybillVeiwComponentComponent,
    ConsignmentComponent,
    // ManifestoViewComponent,
    ChecklistComponent,
    WaybillCreateComponent,
    WaybillRateCalculationComponent,
    // DailyReceivedShipmentComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    WaybillRoutingModule,
    ConfirmDialogModule, 
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    DailyReceivedShipmentComponentComponent,
    ChecklistComponent
  ]
})
export class WaybillModule { }
