import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './guards/auth.guard';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { BarcodeGeneratorComponent } from './print-layout/barcode-generator/barcode-generator.component';
import { RegisterComponent } from './register/register.component';
import { WaybillPrintComponent } from './print-layout/waybill-print/waybill-print.component';
import { ChecklistPrintComponent } from './print-layout/checklist-print/checklist-print.component';
import { WaybillsPrintComponent } from './print-layout/waybills-print/waybills-print.component';
import { ManifestoPrintComponent } from './print-layout/manifesto-print/manifesto-print.component';
import { InvoiceStatementComponent } from './print-layout/invoicestatement-print/invoicestatement-print';
import { InvoicePrintComponent } from './print-layout/invoice-print/invoice-print.component';
import { VatInvoicePrintComponent } from './print-layout/vat-invoice-print/vat-invoice-print.component';
import { VatPurchasePrintComponent } from './print-layout/vat-purchase-print/vat-purchase-print.component';
import { VoucherPrintComponent } from './print-layout/voucher-print/voucher-print.component';




const routes: Routes = [
  {
    path: 'signin',
    component: AuthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: 'invoice-print',
    component: InvoicePrintComponent,
  },
  {
    path: 'vat-invoice-print',
    component: VatInvoicePrintComponent,
  },
  {
    path: 'vat-purchase-print',
    component: VatPurchasePrintComponent,
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'barcode-print/:temp', component: BarcodeGeneratorComponent },
      { path: 'waybill-print/:waybill_no', component: WaybillPrintComponent },
      { path: 'waybill-print/:from_date/:to_date', component: WaybillsPrintComponent }, 
      { path: 'checklist-print/:trip_id', component: ChecklistPrintComponent },
      { path: 'manifesto-print/:trip_id', component: ManifestoPrintComponent },
      { path: 'invoicestatement-print/:customer_id/:customer_name/:customer_address', component: InvoiceStatementComponent },
      { path: 'invoice-print/:invoice_id', component: InvoicePrintComponent },
      { path: 'vat-invoice-print/:invoice_id', component: VatInvoicePrintComponent },
      { path: 'vat-purchase-print/:invoice_id', component: VatPurchasePrintComponent },
      { path: 'voucher-print', component: VoucherPrintComponent },
      
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule
  ],
})
export class AppRoutingModule { }
