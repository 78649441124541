<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class=" col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex padding">
        <div class="card card-stats">
          <div class="card-header card-header-icon">
            <div class="card-icon shadow" style="background-color: #6ccac9;">
              <!-- card-header-info  -->
              <i class="material-icons">calendar_today</i>
            </div>
            <p class="card-category">{{year}}</p>
            <h3 class="card-title">{{year_count}}</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex padding">
        <div class="card card-stats">
          <div class="card-header card-header-icon">
            <div class="card-icon shadow" style="background-color: #ff6c60;">
              <i class="material-icons">date_range</i>
            </div>
            <p class="card-category">{{month}}</p>
            <h3 class="card-title">{{month_count}}</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex padding">
        <div class="card card-stats">
          <div class="card-header card-header-icon">
            <div class="card-icon shadow" style="background-color: #68b654;">
              <i class="material-icons">today</i>
            </div>
            <p class="card-category">{{today | date:'dd-MM-yyyy'}}</p>
            <h3 class="card-title">{{day_count}}</h3>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex padding">
        <div class="card card-stats">
          <div class="card-header card-header-icon">
            <div class="card-icon shadow" style="background-color: #e6b155">
              <i class="material-icons">assessment</i>
            </div>
            <p class="card-category">{{month}} Average</p>
            <h3 class="card-title">{{average | number:'1.0-0'}}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-0">
      <div class="card-body">
        <mat-tab-group style="background-color: darkcyan;" dynamicHeight [selectedIndex]="selected"
          (selectedIndexChange)="onChange($event)">

          <mat-tab label="Daily Received Shipment">
            <app-daily-received-shipment-component (created)="reloadPage()"> </app-daily-received-shipment-component>
          </mat-tab>

          <mat-tab label="Way Bill">
            <app-waybill-report-component (created)="reloadPage()"> </app-waybill-report-component>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>