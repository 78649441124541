import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly rootUrl = environment.rootUrl;

  constructor(private http: HttpClient) { }


  /**
   * 
   */
  getCountries() {
    return this.http.get<any>(this.rootUrl + '/general/get-countries');
  }

  /**
   * 
   */
  getSenders() {
    return this.http.get<any>(this.rootUrl + '/user/get-senders');
  }

  /**
   * 
   */
  getReceivers() {
    return this.http.get<any>(this.rootUrl + '/user/get-receivers');
  }

  /**
   * 
   */
  getSendersNReceivers() {
    return this.http.get<any>(this.rootUrl + '/user/get-senders-n-receivers');
  }

  /**
   * 
   */
  getLedgerNotSetReceivers() {
    return this.http.get<any>(this.rootUrl + '/user/get-ledger-not-set-receivers');
  }

  /**
   * 
   */
  getNextReceiverId() {
    return this.http.get<any>(this.rootUrl + '/user/get-next-receiver-id');
  }

  /**
   * 
   */
  addCustomer(data) {
    return this.http.post<any>(this.rootUrl + '/user/add-customer', data);
  }

  /**
   * 
   */
  getCustomers() {
    return this.http.get<any>(this.rootUrl + '/user/get-customers')
  }

  /**
   * 
   * @param data 
   */
  updateCustomer(data) {
    return this.http.put<any>(this.rootUrl + '/user/update-customer', data);
  }

  /**
   * 
   * @param data
   */
  updateCustomerAddressTrn(data) {
    return this.http.put<any>(this.rootUrl + '/user/update-customer-address-trn',data);
  }

  /**
   * 
   */
  getStaffList() {
    return this.http.get<any>(this.rootUrl + '/user/get-staff-list');
  }

  /**
   * 
   */
  getRoles() {
    return this.http.get<any>(this.rootUrl + '/general/get-roles');
  }

  /**
   * Apply role to specific staff
   * @param data 
   */
  addRole(data) {
    return this.http.post<any>(this.rootUrl + '/general/add-roles', data);
  }

  /**
   * 
   * @param data 
   */
  updateRole(data) {
    return this.http.put<any>(this.rootUrl + '/general/update-roles', data);
  }

  /**
   * 
   * @param data 
   */
  addFaculty(data) {
    return this.http.post<any>(this.rootUrl + '/user/add-staff', data);
  }

  /**
 * 
 * @param data 
 */
  updateStaff(data) {
    return this.http.post<any>(this.rootUrl + '/user/update-staff', data);
  }


  updateFaculty(data) {
    return this.http.post<any>(this.rootUrl + '/user/update-staff', data);
  }

  /**
   * 
   * @param id 
   */
  getMenusByRole(id) {
    return this.http.get<any>(this.rootUrl + '/general/menu-list-by-role/' + id);
  }

  /**
   * update specified role menu-list
   * 
   * @param changed_value 
   * @param menu_id 
   * @param role_id 
   * @param menu_user_type 
   */
  updateMenuControll(changed_value, menu_id, role_id, menu_user_type) {
    return this.http.post<any>(this.rootUrl + '/general/update-role-menu-list',
      { changed_value: changed_value, menu_id: menu_id, role_id: role_id, menu_user_type: menu_user_type });
  }

  /**
   * 
   */
  getStaffRoles() {
    return this.http.get<any>(this.rootUrl + '/general/get-staff-roles');
  }

  /**
   * 
   * @param data 
   */
  registerFacultyUsername(data) {
    return this.http.post<any>(this.rootUrl + '/register-faculty-username', data)
  }

}
