import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  goLiveReports() {
    throw new Error("Method not implemented.");
  }
  // private rootUrl;
  readonly rootUrl = environment.rootUrl;
  id = 1010;
  constructor(private http: HttpClient) {
    // this.rootUrl = 'https://' + window.location.hostname + '/backend/api';
  }
  getStaffLeaves(from, to) {
    return this.http.get<any>(this.rootUrl + '/getLeaveDetailsByStaff/' + `${from}/${to}`)
  }
  getStaffLeaveReport(from, to) {
    return this.http.get<any>(this.rootUrl + '/getStaffLeaveReport/' + `${from}/${to}`)
  }
  getAllStaffLeaveReport(from, to) {
    return this.http.get<any>(this.rootUrl + '/get_all_staff_Leave_stat/' + `${from}/${to}`)
  }
  allStaffLeavApplication(data) {
    return this.http.post<any>(this.rootUrl + '/leave-Application-all', data)
  }
  updateStaffLeaveStatus(status, id, form_data) {
    return this.http.post<any>(this.rootUrl + '/update-leave-status', { id: id, status: status, dates: form_data })
  }

  getStaffByStatus(status) {
    return this.http.get<any>(this.rootUrl + '/staff-byStatus/' + status);
  }


  getDesignationList() {
    return this.http.get<any>(this.rootUrl + '/designation-list');
  }

  getPendingRequest() {
    return this.http.get<any>(this.rootUrl + '/customer-registration/get-registered-list');
  }

  getCustomerSuggestions(id) {
    return this.http.get<any>(this.rootUrl + '/customer-registration/get-customer-list/' + id);
  }

  verifyRegisteredCustomer(data) {
    return this.http.post<any>(this.rootUrl + '/customer-registration/verify-reg-customer', {data});
  }

  verifyEditCustomer(data) {
    return this.http.post<any>(this.rootUrl + '/customer-registration/edit-verified-customer', {data});
  }


  getDesignationLists() {
    return this.http.get<any>(this.rootUrl + '/student-sign-up/designation-list');
  }
  addNewDesignation(designation) {
    return this.http.post<any>(this.rootUrl + '/add-designation', { designation: designation });
  }
  getDepartments() {
    return this.http.get<any>(this.rootUrl + '/get-department-list');
  }

  // notifications
  addNotification(data) {
    return this.http.post<any>(this.rootUrl + '/add-notification', data);
  }
  getNotifications() {
    return this.http.get<any>(this.rootUrl + '/get-notifications');
  }

  getThought() {
    return this.http.get<any>(this.rootUrl + '/get-thought');
  }

  setThought(data) {
    return this.http.post<any>(this.rootUrl + '/set-thought', data);
  }

  uploadStudentImg(image) {
    return this.http.post<any>(this.rootUrl + '/update-profiles-image', image, { reportProgress: true, observe: 'events' })
  }

  //Account Section
  getInvoiceData(batch_id, ledger_sub_id) {
    return this.http.get<any>(this.rootUrl + '/fees/get-invoices/' + batch_id + '/' + ledger_sub_id);
  }






  addNewInvoices(students, invoice_details) {
    return this.http.post<any>(this.rootUrl + '/fees/add-invoices', { std_data: students, invoice_details: invoice_details });
  }

  getInvoiceVerification() {
    return this.http.get<any>(this.rootUrl + '/fees/get-verification-invoices');
  }

  getInvoicesAndRemitted(batch_id, ledger_sub_id, invoice_date) {
    return this.http.get<any>(this.rootUrl + '/fees/get-invoices-and-remitted/' + batch_id + '/' + ledger_sub_id + '/' + invoice_date);
  }

  getAllLiveSubLedgers() {
    return this.http.get<any>(this.rootUrl + '/fees/get-sub-ledgers');
  }

  getGroups() {
    return this.http.get<any>(this.rootUrl + '/fees/get-ledger-group');
  }

  getSubGroups() {
    return this.http.get<any>(this.rootUrl + '/fees/get-ledger-sub-group');
  }









  getAllMainLedgers() {
    return this.http.get<any>(this.rootUrl + '/fees/get-all-main-ledgers');
  }











  //REMITTANCE SECTION STARTS HERE ================================================
  // remittance verification - verification
  getCashierDetails() {
    return this.http.get<any>(this.rootUrl + '/remittance/get-cashier-details');
  }
  getStudentRemittance(user_id) {
    return this.http.get<any>(this.rootUrl + '/remittance/get-student-remittance/' + user_id);
  }
  verifyRemittance(data) {
    return this.http.post<any>(this.rootUrl + '/remittance/verify-remittance', { data: data });
  }
  insertRemittance(data) {
    return this.http.post<any>(this.rootUrl + '/remittance/insert-remittance', data);
  }
  getPaymentReceivedReport(from, to) {
    return this.http.get<any>(this.rootUrl + '/fees/get-fee-collection-summary/' + from + '/' + to);
  }

  getCollectionRegisterReport(from, to) {
    return this.http.get<any>(this.rootUrl + '/fees/get-collection-register/' + from + '/' + to);
  }

  getVerifyRemittanceHistory(from, to) {
    return this.http.get<any>(this.rootUrl + '/remittance/get-verify-remittance-history/' + from + '/' + to);
  }

  getCurrentPaidBatchReport(from, to, batch_id) {
    return this.http.get<any>(this.rootUrl + '/fees/get-fee-collect-batch-summary/' + from + '/' + to + '/' + batch_id);
  }

  getDemandVsCollectionReport(d_from, d_to, c_from, c_to) {
    return this.http.get<any>(this.rootUrl + '/fees/get-fee-demand-collection/' + d_from + '/' + d_to + '/' + c_from + '/' + c_to);
  }

  getCurrentPaidLedgerBatchSummary(from, to, batch_id, ledger_id) {
    return this.http.get<any>(this.rootUrl + '/fees/get-fee-collect-batch-ledger-summary/' + from + '/' + to + '/' +
      batch_id + '/' + ledger_id);
  }

  getCollectionMainHead(from, to) {
    return this.http.get<any>(this.rootUrl + '/fees/get-collection-register-main/' + from + '/' + to);
  }

  getCollectionSubHead(from, to) {
    return this.http.get<any>(this.rootUrl + '/fees/get-collection-register-sub/' + from + '/' + to);
  }

  //REMITTANCE SECTION ENDS HERE ========================================================


  //FINANCE SECION STARTS HERE =============================================================================





  getVoucherPrint(transaction_id) {
    return this.http.get<any>(this.rootUrl + '/finance/get-voucher/' + transaction_id);
  }

  //FINANCE SECION ENDS HERE ===============================================================================
  getJournalReport(from_date, to_date) {
    return this.http.get<any>(this.rootUrl + '/finance/getJournalReport/' + `${from_date}/${to_date}`);
  }
  getJournalReportSub(from_date, to_date, ledger_sub_id) {
    return this.http.get<any>(this.rootUrl + '/finance/getJournalReportSub/' + `${from_date}/${to_date}/${ledger_sub_id}`);
  }
  getJournalReportMain(from_date, to_date, ledger_id) {
    return this.http.get<any>(this.rootUrl + '/finance/getJournalReportMain/' + `${from_date}/${to_date}/${ledger_id}`);
  }


  getTrialBalance(date) {
    return this.http.get<any>(this.rootUrl + '/finance/get-trail-balance/' + date);
  }
  // challan
  searchChallan(id) {
    return this.http.get<any>(this.rootUrl + '/remittance/search-challan/' + id);
  }
  correctChallan(data) {
    return this.http.post<any>(this.rootUrl + '/remittance/correct-challan', { data: data });
  }
  changeRemitDate(challan_id, remitt_date) {
    return this.http.post<any>(this.rootUrl + '/remittance/change-remit-date', { challan_id: challan_id, remitt_date: remitt_date });
  }


  //General Settings Section Start==============================================================================
  //general
  searchStaffList(key) {
    return this.http.get<any>(this.rootUrl + '/general/search-staff-list/' + key);
  }

  getStaffList() {
    return this.http.get<any>(this.rootUrl + '/general/get-staff-list');
  }







  searchStaffUser(key) {
    return this.http.get<any>(this.rootUrl + '/user/search-staff-user/' + key);
  }

  getStaffUsersList() {
    return this.http.get<any>(this.rootUrl + '/user/get-staff-users-list');
  }

  updateUsername(data) {
    return this.http.put<any>(this.rootUrl + '/user/update-username', data);
  }

  searchStudentUser(key) {
    return this.http.get<any>(this.rootUrl + '/general/search-student-user/' + key);
  }

  /**
   * to get student users list
   */
  // getStudentUsersList() {
  //   return this.http.get<any>(this.rootUrl + '/general/get-student-users-list');
  // }

  /**
   * 
   * @param data 
   */
  updateUserStatus(data) {
    return this.http.put<any>(this.rootUrl + '/user/update-user-status', data);
  }





  //General Settings Section End================================================================================

  searchCaste(key) {
    return this.http.get<any>(this.rootUrl + '/search-caste/' + key)
  }
  mergeCastes(data) {
    return this.http.post<any>(this.rootUrl + '/merge-castes', data)
  }
  searchReligion(key) {
    return this.http.get<any>(this.rootUrl + '/search-religion/' + key)
  }
  mergeReligions(data) {
    return this.http.post<any>(this.rootUrl + '/merge-religions', data)
  }
  // staff-sign-up
  /* 
* To get signed up students
* no parameter required
*/
  getSignUpStaff() {
    return this.http.get<any>(this.rootUrl + '/student-sign-up/get-sign-up-staff');
  }

  // editSignUp(data) {
  //   return this.http.post<any>(this.rootUrl + '/student-sign-up/edit-staff-sign-up', data);
  // }

  approveSignUp(data) {
    return this.http.post<any>(this.rootUrl + '/student-sign-up/approve-staff-sign-up', data);
  }

  rejectSignUp(data) {
    return this.http.post<any>(this.rootUrl + '/student-sign-up/reject-staff-sign-up', data);
  }

  getSignUpDepStaff(batch_id) {
    return this.http.get<any>(this.rootUrl + '/student-sign-up/get-sign-up-dep-staff/' + batch_id);
  }

  searchSignUpStaffName(key) {
    return this.http.get<any>(this.rootUrl + '/student-sign-up/staff-sign-up/' + key);
  }

  searchSignUpStaff(id) {
    return this.http.get<any>(this.rootUrl + '/student-sign-up/search-sign-up-staff/' + id);
  }

  getDepStaff(id, status) { // not done
    return this.http.get<any>(this.rootUrl + '/student-sign-up/get-dep-staff/' + id + '/' + status);
  }

  changeStaffEditStatus(id, status) { // not done 
    return this.http.get<any>(this.rootUrl + '/student-sign-up/change-staff-edit-status/' + id + '/' + status);
  }

  enableStaffEditStatus(data) { // not done
    return this.http.post<any>(this.rootUrl + '/student-sign-up/enable-staff-edit-status', { staff_list: data });
  }

  // To add scholar type
  addScholarDetails(batch_id, students_ids, change_form, status_id) {
    return this.http.post<any>(this.rootUrl + '/student/update-student-scholar-type', { students_ids: students_ids, batch_id: batch_id, change_form: change_form, status_id: status_id });
  }

  // // To add Quota
  addAdmissionQuotaDetails(batch_id, students_ids, change_form, status_id) {
    return this.http.post<any>(this.rootUrl + '/student/update-student-admission-quota', { batch_id: batch_id, students_ids: students_ids, change_form: change_form, status_id: status_id });
  }

  /*
  * For to get attendance marked in attendance deletion
  */
  getAttendanceMarked(date, time_slot_id, user_id) {
    return this.http.get<any>(this.rootUrl + '/attendance/get-teacher-marked-students-attendance/' + date + '/' + time_slot_id + '/' + user_id);
  }

  /*
  * To delete attendance marked by a user
  */
  deleteAttendanceOfUser(data) {
    return this.http.post<any>(this.rootUrl + '/attendance/remove-attendance-of-user', data);
  }
  getCourseWiseApplicationReport() {
    return this.http.get<any>(this.rootUrl + '/student-admission/get-application-count');
  }
  getVerifiedApplicationReport() {
    return this.http.get<any>(this.rootUrl + '/student-admission/get-appl-verification-details');
  }
  getDepartmentList() {
    return this.http.get<any>(this.rootUrl + '/student-sign-up/get-department-list');
  }
  signUp(data) {
    return this.http.post<any>(this.rootUrl + '/student-sign-up/staff-sign-up', data);
  }
  /**
   * to transfer faculty
   */
  transferFaculty(data) {
    return this.http.post<any>(this.rootUrl + '/faculty/transfer-faculty', data);
  }

  // tho edit quick access menu
  // editQuickAccess(data) {
  //   return this.http.post<any>(this.rootUrl + '/add-quick-menu', data)
  // }

  /**
   * to add opening balance
   */
  addOpeningBalance(data) {
    return this.http.post<any>(this.rootUrl + '/finance/add-opening-balance', { data: data });
  }

  /**
   * to update opening balance
   */
  updateOpeningBalance(data) {
    return this.http.post<any>(this.rootUrl + '/finance/update-opening-balance', data);
  }
  // getStudentDetails(data) {
  //   return this.http.post<any>(this.rootUrl + '/get-student-details-by-admsn-no', data);
  // }

  // to get club in student report
  getStudentClub(student_id) {
    return this.http.get<any>(this.rootUrl + '/student-club-details/' + student_id);
  }
  // to get class in student report
  getStudentClass(student_id) {
    return this.http.get<any>(this.rootUrl + '/student/studentTeachers/' + student_id);
  }

  // to get student details by adm no
  getAllStudentDetails(admission_number) {
    return this.http.post<any>(this.rootUrl + '/student-detail-report', { admission_number: admission_number });
  }

  /**
   * to get student temrly attendance report by student_id
   * 
   * @params student_id
   */
  getTermlyAttendanceReportStudent(admission_number) {
    return this.http.post<any>(this.rootUrl + '/get-attendance-report', { admission_number: admission_number });
  }
  /**
   * to get paperwise Attendance
   * 
   * @params admno,sem
   */
  getPaperAttendance(sem, admno) {
    return this.http.post<any>(this.rootUrl + '/get-paper-attendance', {
      semester: sem,
      admission_number: admno
    });
  }
  /**
  * to get question-bank sectionwise-questions
  * 
  * @params paper_id
  */
  getSectionWiseQuestion(paper_id) {
    return this.http.get<any>(this.rootUrl + '/question-bank/sectionwise-questions/' + paper_id);
  }

  /**
   * to get all SMS templates
   */
  getSmsAllTemplates() {
    return this.http.get<any>(this.rootUrl + '/sms/get-sms-templates');
  }

  /**
   * to update template status
   * 
   * @param template_id 
   * @param status 
   */
  toUpdateTemplateStatus(template_id, status) {
    return this.http.post<any>(this.rootUrl + '/sms/update-institute-sms-template',
      {
        templates_id: template_id,
        status: status
      }
    );
  }
  /**
  * smsreport- get sms history
  * 
  * @param from 
  * @param to 
  */
  getSmsHistory(from, to) {
    return this.http.get<any>(this.rootUrl + '/sms/get-sms-history/' + `${from}/${to}`);
  }
  /**
  * smsreport- get sms delivery status
  * @param guid
  */
  getSmsDeliveryStatus(guid) {
    return this.http.get<any>(this.rootUrl + '/sms/get-sms-delivery-staus/' + guid);
  }
}
