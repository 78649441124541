import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';

@Component({
  selector: 'app-checklist-print',
  templateUrl: './checklist-print.component.html',
  styleUrls: ['./checklist-print.component.scss']
})
export class ChecklistPrintComponent implements OnInit {

  data: any;
  ind_trip: any;
  trip_details: any;
  total_quantity: number;
  total_loaded_pkg: number;
  trips: any;

  constructor(private tripservice: TripService, route: ActivatedRoute, private printservice: PrintServiceService, private cdref: ChangeDetectorRef) {
    this.data = route.snapshot.params['trip_id'];
  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  ngOnInit(): void {
    this.getData(this.data);
  }

  getData(id) {
    this.tripservice.getCheckList(id).subscribe((res: any) => {
      // console.log(res);

      this.trips = res.trips;
      let indtrip = this.trips.filter(element => element.id === parseInt(id));
      this.ind_trip = indtrip[0];
      this.trip_details = res.data;
      // console.log(this.ind_trip);

      this.setChecklist();

      // this.ind_trip_2 = res.trips[0];
      // this.trip_details2 = res.data;
      // this.total_quantity = 0;
      // for (let i = 0; i < this.trip_details2.length; i++) {
      //   this.trip_details2[i].loaded_array = [];
      //   this.trip_details2[i].loaded_array = this.trip_details2[i].loaded_pkg_list.split(',');
      //   this.total_quantity += +this.trip_details2[i].total_quantity;
      // }

      // // console.log(this.ind_trip_2);
      var file_name = "Checklist " + this.ind_trip?.trip_name
      this.printservice.onDataReady(file_name,'checklist-print','landscape');
    });
  }

  setChecklist() {
    this.total_quantity = 0;
    this.total_loaded_pkg = 0;
    var new_array = [];
    if (this.trip_details.length > 0) {
      // console.log(this.trip_details.length);

      for (let i = 0; i < this.trip_details.length; i++) {
        this.trip_details[i].loaded_array = [];

        var loaded_pkg_list = this.trip_details[i].loaded_pkg_list.split(',');
        var waybill_list = this.trip_details[i].waybill_list.split('/');
        // waybill_list.sort((a, b) => a - b);
        var all_waybills = this.trip_details[i].waybill_list.split('/');
        all_waybills.sort((a, b) => a - b);

        var unique_waybills = [];
        $.each(all_waybills, function (i, el) {
          if ($.inArray(el, unique_waybills) === -1) unique_waybills.push(el);
        });

        let w_splitter = 0
        let c_id = 0
        let waybills = '';
        var packages = []
        // for (let j = 0; j < waybill_list.length; j++) {
        //   c_id = waybill_list[j]
        //   if (c_id != o_id) {
        //     o_id = c_id
        //     packages.push(parseInt(loaded_pkg_list[j]))
        //     if (waybills) {
        //       waybills += '/' + c_id;
        //     } else {
        //       waybills += c_id;
        //     }
        //   } else {
        //     packages[packages.length - 1] += parseInt(loaded_pkg_list[j])
        //   }
        // }

        for (let j = 0; j < unique_waybills.length; j++) {
          c_id = unique_waybills[j]
          w_splitter++;
          if (waybills) {
            // waybills += '/' + c_id;
            if (w_splitter > 3) {
              w_splitter = 0;
              var seperator = ' /';
            } else {
              var seperator = '/';
            }
            waybills += seperator + c_id;
          } else {
            waybills += c_id;
          }
          packages[j] = 0;
          for (let k = 0; k < waybill_list.length; k++) {
            let waybill = waybill_list[k]

            if (c_id === waybill) {
              packages[j] = packages[j] + parseInt(loaded_pkg_list[k])
            }
          }
        }

        var l = packages.length;
        // console.log("length " + l + " id " + this.trip_details[i]);

        if (l > 10) {
          var new_packages = packages.splice(10, 20)
          new_array.push({ 'index': i, 'receiver_code': this.trip_details[i].receiver_code, 'waybill_list': '', 'receiver_name': '', 'loaded_array': new_packages, 'narration': '' })
        }

        this.trip_details[i].loaded_array = packages
        this.trip_details[i].waybill_list = waybills

        this.total_quantity += +this.trip_details[i].total_quantity;
        this.total_loaded_pkg += +this.trip_details[i].total_loaded_pkg;
      }
    }

    var index;
    for (let j = 0; j < new_array.length; j++) {
      index = new_array[j].index;
      this.trip_details.splice(index + 1, 0, new_array[j])
    }

    // console.log(this.ind_trip);
    // this.checklist_verification = 'Checklist verified by ' + this.ind_trip?.staff_name + '[' + this.ind_trip?.staff_code + '] on '
    // var created_at = new Date(this.ind_trip.created_at + ' UTC');
    // this.checklist_verification += created_at;
    // // console.log(this.checklist_verification);
  }

}
