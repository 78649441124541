import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { WaybillService } from 'app/services/waybill.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-daily-received-shipment-component',
  templateUrl: './daily-received-shipment-component.component.html',
  styleUrls: ['./daily-received-shipment-component.component.scss']
})
export class DailyReceivedShipmentComponentComponent implements OnInit {

  // short key
  mac = 'command+s';
  win = 'ctrl+s';
  isMac = navigator.platform.includes('Mac');
  saveCommand = this.isMac ? this.mac : this.win;
  saveCommandTitle = this.isMac ? '⌘+s' : this.win;

  mac1 = 'command+c';
  win1 = 'ctrl+c';
  isMac1 = navigator.platform.includes('Mac');
  cancelCommand = this.isMac1 ? this.mac1 : this.win1;
  cancelCommandTitle = this.isMac1 ? '⌘+c' : this.win1;

  displayedWayBillsDaily = ['index', 'waybill_no', 'sender_name', 'receiver_name', 'item_name', 'quantity', 'narration'];
  datasourceDaily = new MatTableDataSource<any>();
  @ViewChild('table1', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  from: Date;
  to: Date;
  today_date: string;
  current_date: any;
  today_date_h: string;
  daily_waybill: any;
  show_full: boolean;
  detailed: any;
  not_detailed: any[];
  loaded_total: number = 0;
  quantity_total: number = 0;
  
  constructor(private waybillservice: WaybillService, private pipe: DatePipe,  private hotkeysService: HotkeysService) { 
    this.hotkeysService.add([
      new Hotkey(
        this.saveCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.getDaily(this.current_date);

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          // console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]
      
    );
  }

  ngOnInit(): void {
    this.current_date = new Date();
    this.getDaily(this.current_date);
    this.show_full = false;
  }

  /**
   * to get daily waybill by date
   * 
   * @param date 
   */
  getDaily(date) {
    // console.log(date);
    // console.log(this.current_date);
    
    const from = this.pipe.transform(date, 'yyyy-MM-dd');
    this.waybillservice.getWaybillByDate(from).subscribe((res: any) => {
      // console.log(res);
      this.daily_waybill = res.data;

      let waybills = new Array;
      let id = 0;
      let o_id = 0;
      let item_name = '';
      let quantity = 0;
      let consignment_id = 0;
      let o_consignment_id = 0;

      res.data.forEach(element => {
        id = element.id;
        consignment_id = element.consignment_id;

        if (id != o_id) {
          waybills.push(element)
          o_id = element.id;
          o_consignment_id = element.consignment_id;

          item_name = element.item_name
          quantity = element.quantity
        } else {
          item_name += ', ' + element.item_name;

          if (consignment_id != o_consignment_id) {
            quantity += element.quantity;
            o_consignment_id = element.consignment_id;
          } else {
            quantity = element.quantity;
          }

          let new_value = { 'id': id, 'waybill_no': element.waybill_no, 'sender_name': element.sender_name, 'receiver_code': element.receiver_code, 'receiver_name': element.receiver_name, 'item_name': item_name, 'quantity': quantity, 'narration': element.narration }

          waybills[waybills.length - 1] = new_value;
        }
      });

      this.detailed = res.data;
      this.not_detailed = waybills;

      this.datasourceDaily = new MatTableDataSource(waybills);
      this.loaded_total = this.datasourceDaily.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
      this.quantity_total = this.datasourceDaily.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);


      // console.log(waybills);

      let data = [];
      if (this.show_full) {
        data = this.detailed
      } else {
        data = this.not_detailed
      }

      this.datasourceDaily = new MatTableDataSource(data);
      this.datasourceDaily.paginator = this.paginator
      this.datasourceDaily.sort = this.sort1;
    });
  }

  showControl() {
    let data = [];
    if (this.show_full) {
      this.show_full = false;
      data = this.not_detailed
    } else {
      this.show_full = true;
      data = this.detailed
    }

    this.datasourceDaily = new MatTableDataSource(data);
    this.datasourceDaily.sort = this.sort1;
  }

  getQuantityTotal() {
    return this.datasourceDaily.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);
  }
  getLoadedTotal() {
    return this.datasourceDaily.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
  }

  /**
   * Excel creation
   */
  async generateExcel() {

    this.from = new Date();
    this.today_date = this.pipe.transform(this.from, 'dd/MM/yyyy');
    this.today_date_h = this.pipe.transform(this.from, 'dd-MM-yyyy');

    // Excel Title, Header, Data
    const title = 'Inshah ALLAH .. Every Move A Success';
    const header = ['DATE', 'CONS#', 'SHIPPER', 'CONSIGNEE', 'COMMODITY', 'PKG', 'LOAD-PKG', 'REMARKS'];

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Way bill ' + this.today_date_h);


    // Add Row and formatting
    const titleRaw = worksheet.addRow([title]);
    titleRaw.font = { name: 'Calibri', family: 4, size: 16, underline: 'none', bold: false };
    titleRaw.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells('A1:G1');

    const rowValues = [];
    rowValues[1] = 'DATE ' + this.today_date;
    rowValues[4] = 'DAILY RECEIVED ITEMS';

    const subTitleRow = worksheet.addRow(rowValues);
    subTitleRow.font = { name: 'Verdana', family: 4, size: 12, underline: 'none', bold: true };
    subTitleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

    rowValues[1] = '';
    rowValues[4] = 'GOLDEN ELITE CARGO';

    const subTitleRow1 = worksheet.addRow(rowValues);
    subTitleRow1.font = { name: 'Verdana', family: 4, size: 11, underline: 'none', bold: false };

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.font = { name: 'Verdana', family: 4, size: 11, color: { argb: '#FF0000' }, underline: 'none', bold: true };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // this.daily_waybill = this.daily_waybill.sort((a,b) => b - a);
    // console.log(this.daily_waybill);


    this.daily_waybill.forEach(d => {
      const rowValues = [];
      rowValues[1] = this.pipe.transform(d.waybill_date, 'dd/MM/yyyy');
      rowValues[2] = d.receiver_code + '/' + d.waybill_no;
      rowValues[3] = d.sender_name.toUpperCase();
      rowValues[4] = d.receiver_name.toUpperCase();
      rowValues[5] = d.item_name.toUpperCase();
      rowValues[6] = d.quantity;
      rowValues[7] = d.loaded_pkg;
      // rowValues[8] = d.narration.toUpperCase();
      rowValues[8] = d.narration != null ? d.narration.toUpperCase() : d.narration;
      const contentRow = worksheet.addRow(rowValues);
      contentRow.font = { name: 'Verdana', family: 4, size: 10, underline: 'none', bold: false };

      // Cell Style : Fill and Border
      contentRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
          // bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    const rowValues1 = [];
    var emptyRow = worksheet.addRow(rowValues1);
    emptyRow.font = { name: 'Verdana', family: 4, size: 10, underline: 'none', bold: false };
    // Cell Style : Fill and Border
    emptyRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(8).width = 50;

    // Footer Row
    const footeValues = [];
    // footeValues[6] = worksheet.getCell(`=SUM(F6:F${footerRow.number})`);
    const footerRow = worksheet.addRow(footeValues);

    footerRow.getCell(6).value = this.quantity_total;
    // footerRow.getCell(6).value = this.getQuantityTotal();
    footerRow.getCell(7).value = this.loaded_total;
    // footerRow.getCell(7).value = this.getLoadedTotal();
    footerRow.font = { name: 'Verdana', family: 4, size: 20, underline: 'none', bold: true };
    footerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    // worksheet.getCell('B1').value = {formula : 'SUM(A1,A2)', result: (worksheet.getCell('A1').value + worksheet.getCell('A2').value) }


    // Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'DAILY RECEIVED.xlsx');
    });

  }

}
