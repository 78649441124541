<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">

        <div class="row mt-2">
          <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <mat-form-field appearance="outline">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate matInput placeholder="From" [(ngModel)]="from">
                <input matEndDate matInput placeholder="To" [(ngModel)]="to">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-md-4 col-sm-4 col-lg-4" style="margin-left: 5px; margin-top: 5px; margin-bottom: 10px;">
            <button type="submit" mat-raised-button class="btn-success" style="height: 32px;" (click)="submit_date()"
              title="{{ saveCommandTitle }}">Submit ({{ saveCommandTitle }})</button>
          </div>
        </div>

        <mat-tab-group class="color" dynamicHeight>
          <mat-tab label="Consignment on Board">
            <div class="card mt-0">
              <div class="card-body">
                <div class="row" *ngIf="datasource?.data.length > 0">
                  <div class="col">
                    <button mat-button class="float-right" color="primary" (click)="export()">
                      <mat-icon>get_app</mat-icon>Export
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table mat-table [dataSource]="datasource" matSort
                        class="mat-elevation-z8 table table-bordered table-striped" id="consignmentOnBoard">
                        <ng-container matColumnDef="index" class="table">
                          <th mat-header-cell *matHeaderCellDef class="text-light" style="padding-right: 10px;"> Sl
                          </th>
                          <td mat-cell *matCellDef="let element;index as i"> {{ (paginatorRef.pageIndex *
                            paginatorRef.pageSize) + (i + 1) }}</td>
                          <!-- {{i+1}} -->
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="waybill_date">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="nowrap text-light pl-2"
                            style="text-align:left"> Date </th>
                          <td mat-cell *matCellDef="let element" class="pl-2 nowrap">
                            {{element?.waybill_date | date:'dd-MM-yyyy'}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="id">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light nowrap"
                            style="padding-left:40px;"> Way Bill
                          </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left">
                            {{element?.receiver_code?element?.receiver_code+'/':'/'}}{{element?.waybill_no}}</td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="sender_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light"
                            style="padding-left:40px;"> Sender </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left">
                            {{element?.sender_name}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="receiver_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light"
                            style="padding-left:40px;"> Receiver </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left">
                            {{element?.receiver_name}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="item_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light"
                            style="padding-left:40px;"> Commodity </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left">
                            {{element?.item_name}} </td>
                          <td mat-footer-cell *matFooterCellDef class="nowrap pl-2" style="text-align:right"><b> Total
                            </b>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pr-3"
                            style="padding-left:40px;">
                            Balance
                          </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pr-3" style="text-align:right">
                            {{element?.quantity - element?.packed}}
                          </td>
                          <td mat-footer-cell *matFooterCellDef class="text-right" style="padding-left:40px;"><b>
                              {{getBalanceTotal()}} </b></td>
                        </ng-container>
                        <ng-container matColumnDef="tracking_position_status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light"
                            style="padding-left:40px;"> Status
                          </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left"> Packed
                          </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <tr mat-header-row class="headColor" *matHeaderRowDef="displayedConsignmentOnBoard"></tr>
                        <tr mat-row class="bodyColor" *matRowDef="let row; columns: displayedConsignmentOnBoard;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedConsignmentOnBoard"></tr>
                      </table>

                      <mat-paginator [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons #paginatorRef>
                      </mat-paginator>
                      <ng-container *ngIf="!datasource || datasource?.data.length == 0">
                        <h4 class="text-danger text-center">NO RECORDS AVAILABLE</h4>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Consignment History">
            <div class="card mt-0">
              <div class="card-body">
                <div class="row" *ngIf="datasource1?.data.length > 0">
                  <div class="col">
                    <button class="float-right" mat-button color="primary" (click)="export1()">
                      <mat-icon>get_app</mat-icon>Export
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table mat-table [dataSource]="datasource1" matSort #sort1="matSort"
                        class="mat-elevation-z8 table table-bordered table-striped" id="consignmentHistory">
                        <ng-container matColumnDef="index" class="table">
                          <th mat-header-cell *matHeaderCellDef class="text-light" style="padding-right: 20px;"> Sl
                          </th>
                          <td mat-cell *matCellDef="let element;index as i"> {{i+1}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="waybill_date">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1 pr-1"> Date </th>
                          <td mat-cell *matCellDef="let element" class="nowrap"> {{element?.waybill_date |
                            date:'dd-MM-yyyy'}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="id">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light nowrap pl-1"> Way
                            Bill
                          </th>
                          <td mat-cell *matCellDef="let element" class=" pl-1" style="text-align:left">
                            {{element?.receiver_code?element?.receiver_code+'/':'/'}}{{element?.waybill_no}}</td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="sender_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1 "> Sender </th>
                          <td mat-cell *matCellDef="let element" class=" pl-1" style="text-align:left">
                            {{element?.sender_name}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="receiver_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1">
                            Receiver </th>
                          <td mat-cell *matCellDef="let element" class=" pl-1" style="text-align:left">
                            {{element?.receiver_name}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="item_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1"> Commodity
                          </th>
                          <td mat-cell *matCellDef="let element" class="nowrap pl-2" style="text-align:left">
                            {{element?.item_name}} </td>
                          <td mat-footer-cell *matFooterCellDef><b> Total </b></td>
                        </ng-container>
                        <ng-container matColumnDef="loaded_pkg">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1"> Packed </th>
                          <td mat-cell *matCellDef="let element" class="pr-2" style="text-align:right">
                            {{element?.loaded_pkg}} </td>
                          <td mat-footer-cell *matFooterCellDef class="pr-3" style="text-align:right"><b>
                              {{getPackedTotal1()}} </b></td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class=" text-light pl-1 "
                            style="text-align:right;"> PKG </th>
                          <td mat-cell *matCellDef="let element" class="pr-2" style="text-align:right">
                            {{element?.quantity}} </td>
                          <td mat-footer-cell *matFooterCellDef class="pr-3" style="text-align:right"><b>
                              {{getQuantityTotal1()}} </b></td>
                        </ng-container>
                        <ng-container matColumnDef="trip_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1"> Trip </th>
                          <td mat-cell *matCellDef="let element" class=" nowrap pl-2" style="text-align:left">
                            {{element?.trip_name}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="check_point">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1"> Position </th>
                          <td mat-cell *matCellDef="let element"> {{element?.check_point}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="tracking_position_status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-light pl-1 "> Status </th>
                          <td mat-cell *matCellDef="let element"> {{element?.tracking_position_status}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <tr mat-header-row class="headColor" *matHeaderRowDef="displayedConsignmentHistory"></tr>
                        <tr mat-row class="bodyColor" *matRowDef="let row; columns: displayedConsignmentHistory;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedConsignmentHistory"></tr>
                      </table>

                      <mat-paginator [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons #paginatorRef>
                      </mat-paginator>
                      <ng-container *ngIf="!datasource1 || datasource1?.data.length == 0">
                        <h4 class="text-danger text-center">NO RECORDS AVAILABLE</h4>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>