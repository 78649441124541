<div class="main-content body" id="invoicestatement-print">
    <div class="container-fluid">
        <!-- <div class="card mt-0">
            <div class="card-body"> -->

                <!-- start dropdown serch -->
                <!-- <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 padding">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter Sundry debitors Details</mat-label>
                            <input matInput type="text" placeholder="Sundry debitors Details"
                                (keyup)="searchReceivers($event)" [matAutocomplete]="auto1">
                            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn1"
                                (optionSelected)="getReceiverDetails($event.option.value)">
                                <mat-option *ngFor="let receiver of receiver_list" [value]="receiver">
                                    {{receiver?.customer_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div> -->

               
                    <div class="card-body">

                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                <h5 class="text-center"> GOLDEN ELITE LOGISTICS</h5>
                                <h6 class="text-center">PO BOX 1869</h6>
                                <h6 class="text-center"><span>17483473</span></h6>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <h6>PARTY : {{customer_name}}</h6>
                                <h6>ADDRESS : {{customer_address}}</h6>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>Date</b></th>
                                        <th><b>Invoice No</b></th>
                                        <th><b>Opening Amount</b></th>
                                        <th><b>Pending Amount</b></th>
                                        <th><b>Due On</b></th>
                                        <th><b>Overdue by days</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoice of invoices;index as i">
                                        <td class="nowrap">{{invoice?.invoice_date | date:"dd-MM-yyyy"}}</td>
                                        <td class="text-center">{{invoice?.id}}</td>
                                        <td class="text-right">{{invoice?.invoice_amount | number : '1.3-3'}}</td>
                                        <td class="text-right">{{invoice?.balance | number : '1.3-3'}}</td>
                                        <td class="nowrap">{{invoice?.due_date | date:"dd-MM-yyyy"}}</td>
                                        <td class="text-center">{{calculateDiff(invoice?.due_date)}}</td>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td class="text-right" colspan="2">Sub Total</td>
                                        <td class="text-right">{{invoice_total | number : '1.3-3'}}</td>
                                        <td class="text-right">{{total_pending | number : '1.3-3'}}</td>
                                        <td colspan="2"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                        <td class="text-right">On Account</td>
                                        <td class="text-right" colspan="2">{{on_account_advance | number : '1.3-3'}}
                                        </td>
                                        <td colspan="2"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" colspan="4">{{total_balance | number : '1.3-3'}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="table-responsive" *ngIf="on_account_advance > 0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>Date</b></th>
                                        <th><b>Receipt No</b></th>
                                        <th><b>Opening Amount</b></th>
                                        <th><b>Balance Amount</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let advance of on_account_advances;index as i">
                                        <td class="nowrap">{{advance?.remitt_date | date:"dd-MM-yyyy"}}</td>
                                        <td class="text-center">{{advance?.receipt_no}}</td>
                                        <td class="text-right">{{advance?.on_account | number : '1.3-3'}}</td>
                                        <td class="text-right">{{advance?.balance | number : '1.3-3'}}</td>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td class="text-right" colspan="3">Total</td>
                                        <td class="text-right" colspan="1">{{on_account_advance | number : '1.3-3'}}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
             



