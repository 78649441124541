import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthserviceService } from './authservice.service';
import { first } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import * as jQuery from 'jquery';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})

export class AuthComponent implements OnInit {
  response;

  // resolved(captchaResponse: string) {
  //   // console.log(`Resolved captcha with response: ${captchaResponse}`);
  // }
  // public submit(captchaResponse: string): void {
  //   // console.log(captchaResponse);
  // }

  hide = true;
  loginform: FormGroup;
  resetform: FormGroup;
  submitted = false;
  loginfailed = false;
  forget = false;
  institution_name: any;
  url = environment.url;
  folder = environment.rootFolder;
  ask_user_id: boolean = false;
  is_mobile_number: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authservice: AuthserviceService,
    private toast: ToastrService,
  ) {
  }

  readonly rootUrlhost = environment.rootUrlhost;

  ngOnInit() {
    // console.log(this.rootUrlhost);
    // console.log(this.rootUrlhost.hostname.split('.')[0]);
    // console.log(this.rootUrlhost.hostname.split('.')[1]);
    
    this.institution_name = "Golden Elite Logistics";

    this.loginform = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      user_type: '1',
    })

    this.resetform = this.formBuilder.group({
      mobile: new FormControl(''),
      email: new FormControl(''),
      user_type: new FormControl(''),
    })

    this.is_mobile_number = false;
  }

  checkLogin() {
    if (this.loginform.invalid) {
      return;
    } else {
      this.submitted = true;
      this.loginform.get('user_type').setValue(1);
      // console.log(this.loginform);

      this.authservice.login(this.loginform.value).pipe(first()).subscribe(data => {
        // // console.log('success' + JSON.stringify(data));
        this.router.navigate(['/home']);
      },
        _error => {
          this.onReset();
          // this.toast.error(_error.error.error.error_message, 'failed');
          // this.toast.error(_error, 'failed');
          if (_error.error.success === false) {
            this.toast.error(_error.error.error.message, 'Failed');
            // this.toast.error('Authentication Failed', 'Failed');
          }
          // this.toast.error('Enter Credentials Correctly', 'failed');

          if (_error.error.success === 1) {
            this.ask_user_id = true;
          }
          // // console.log(_error);
          this.loginfailed = true;
        });
    }
  }

  onReset() {
    this.submitted = false;
  }

  requestResetEmail() {

    this.resetform.patchValue({
      user_type: 1
    });

    this.authservice.resetRequest(this.resetform.value).subscribe((res: any) => {
      // console.log(res);
      this.toast.success(res.message);
      this.forget = false;
      this.is_mobile_number = false;
      this.ask_user_id = false;
    },
      _error => {
        // console.log(_error);
        this.toast.warning(_error.error.message);
      }
    );
  }

}
