
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Tokens } from '../models/tokens';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {
  loggedUser: string;
  readonly rootUrl = environment.rootUrl;
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'JWT_TOKEN';
  constructor(private http: HttpClient) {
  }

  login(data) {
    return this.http.post<any>(this.rootUrl + `/signin`, data)
      .pipe(map(user => {
        this.doLoginUser(user, user);
        return user;
      }));
  }

  private doLoginUser(user: any, token: Tokens) {
    if (user.userDetails) {
      this.loggedUser = user.userDetails.faculty_name;
      localStorage.setItem('username', user.userDetails.faculty_name);
    }
    localStorage.clear();
    localStorage.setItem('user_name', user.username);
    localStorage.setItem('type', user.user_type);
    localStorage.setItem('is_valid', 'valid');
    this.storeJwtToken(token);
  }

  private storeJwtToken(token: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, token.token);
  }

  /**
  * Password reset link using registered email
  * 
  * @param data 
  */
  resetRequest(data) {
    return this.http.post<any>(this.rootUrl + '/password/reset-request', data);
  }

  /**
   * Validate token
   */
  validateResetToken(token) {
    return this.http.get<any>(this.rootUrl + '/password/validate-token/' + token)
  }

  /**
   * Change password
   */
  resetPassword(data) {
    return this.http.post<any>(this.rootUrl + '/password/reset-password', data)
  }

  /**
   * Logout
   */
  logout() {
    return this.http.get<any>(this.rootUrl + '/user/logout')
      .pipe(
        tap(success => this.doLogoutUser()),
        mapTo(true),
        catchError(error => {
          this.doLogoutUser()
          return of(false);
        }));
  }

  /**
   * Logined user details
   */
  getUser() {
    return this.http.get<any>(this.rootUrl + '/user/getuser')
  }
  /** *************** */

  isLoggedIn() {
    const user = localStorage.getItem('type');
    const valid = localStorage.getItem('is_valid');
    return !!this.getJwtToken() && user === '1' && valid === 'valid';
  }

  refreshToken() {
    return this.http.post<any>(`${this.rootUrl}/refresh`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: any) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.clear();
    localStorage.removeItem('username');
    localStorage.removeItem('user_name');
    localStorage.removeItem('type');
    localStorage.removeItem('is_valid');

    // localStorage.removeItem(this.REFRESH_TOKEN);
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getinstituteWeb(name) {
    return this.http.get<any>(this.rootUrl + '/getInstitute/' + name)
  }
  requestPasswordReset(data) {
    return this.http.post<any>(this.rootUrl + '/password/faculty-reset-request', data)
  }

  changePassword(data) {
    return this.http.post<any>(this.rootUrl + '/password/update-current-password', data)
  }
}
