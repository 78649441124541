import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RandomGuard } from 'app/guards/random.guard';
import { ConsignmentComponent } from './consignment/consignment.component';
import { CreateWayBillComponentComponent } from './create-way-bill-component/create-way-bill-component.component';
import { WayBillComponentComponent } from './way-bill-component/way-bill-component.component';
import { WaybillEditComponentComponent } from './waybill-edit-component/waybill-edit-component.component';
import { WaybillVeiwComponentComponent } from './waybill-veiw-component/waybill-veiw-component.component';
import { WaybillCreateComponent } from './waybill-create/waybill-create.component';
import { WaybillRateCalculationComponent } from './waybill-rate-calculation/waybill-rate-calculation.component';

const routes: Routes = [
  { path: 'waybill', component: WayBillComponentComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  { path: 'edit', component: WaybillEditComponentComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  { path: 'create-way-bill', component: CreateWayBillComponentComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  { path: 'view', component: WaybillVeiwComponentComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  { path: 'consignment', component: ConsignmentComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  { path: 'way-bill-create', component: WaybillCreateComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  // { path: 'waybill-rate-calculation', component: WaybillRateCalculationComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  { path: 'waybill-rate-calculation', component: WaybillRateCalculationComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
  // { path: 'vehicle', component: TestingComponent, canActivate: [RandomGuard], canLoad: [RandomGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)], 
  exports: [RouterModule]
})
export class WaybillRoutingModule { }
