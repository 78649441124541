import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';
import { VatInvoiceService } from 'app/services/vat-invoice.service';

@Component({
  selector: 'app-vat-invoice-print',
  templateUrl: './vat-invoice-print.component.html',
  styleUrls: ['./vat-invoice-print.component.scss']
})
export class VatInvoicePrintComponent implements OnInit {
  invoice_id: any;
  individual_invoice: any[];
  head_data: any;
  words_amount: any;
  total_qty:any;
  total_gross:any;
  total_vat:any;
  total_tax:any;

  constructor(private tripService: TripService,private vatService: VatInvoiceService, route: ActivatedRoute, private printservice: PrintServiceService) {
    this.invoice_id = route.snapshot.params['invoice_id'];
    // console.log(this.invoice_id);
  }

  ngOnInit(): void {
    this.vatService.getVatInvoiceDetails(this.invoice_id).subscribe((res: any) => {
      this.head_data = res.vat_invoice;
      this.individual_invoice = res.vat_invoice_details;
      
      this.total_qty = this.individual_invoice.reduce((acc, curr) => {
        return acc + Number(curr.qty);
      }, 0);
      this.total_gross = this.individual_invoice.reduce((acc, curr) => {
        return acc + Number(curr.total);
      }, 0);
      this.total_vat = this.individual_invoice.reduce((acc, curr) => {
        return acc + Number(curr.vat_amount);
      }, 0);
      this.total_tax = this.individual_invoice.reduce((acc, curr) => {
        return acc + Number(curr.taxable_amount);
      }, 0);
      this.words_amount = res.amount_words;
      if(this.individual_invoice.length <10){
        let fillCount = 10 - this.individual_invoice.length;
        let filledArray = new Array(fillCount).fill(undefined);
        this.individual_invoice = this.individual_invoice.concat(filledArray);
      }

      var file_name = "Vat Invoice " + this.invoice_id
      this.printservice.onDataReady(file_name,'printSection','A4');
    });

  }

}
