import { Component, OnInit } from '@angular/core';
import { PrintServiceService } from 'app/services/print-service.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnInit {

  constructor(private printservice: PrintServiceService,) { }

  ngOnInit() {
  }

}
