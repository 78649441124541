import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintServiceService {
  isPrinting = false;
  getInstituteDetails: any;

  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: string[]) {

    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData]
        }
      }]);
  }

  printSingleDocument(documentName: string, documentData: string) {
    // console.log(documentData);
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData]
        }
      }]);
  }
  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string
  {
      const htmlStr: string[] = [];
      const elements = document.getElementsByTagName(tagName);
      for (let idx = 0; idx < elements.length; idx++)
      {
          htmlStr.push(elements[idx].outerHTML);
      }
  
      return htmlStr.join('\r\n');
  }
  onDataReady(title,divId,size) {
    setTimeout(() => {
      // document.title = title
      // Start set landscape
      // var css = '@page { size: portrait; }',
      //   head = document.head || document.getElementsByTagName('head')[0],
      //   style = document.createElement('style');
      // style.type = 'text/css';
      // style.media = 'print';
      // style.appendChild(document.createTextNode(css));
      // head.appendChild(style);
      // end landscape


      // var head = document.head || document.getElementsByTagName('head')[0];
      // var style = document.createElement('style');
      // style.type = 'text/css';
      // style.media = 'print';
    
      // style.appendChild(document.createTextNode('@page { size: landscape; }'));
    
      // // head.appendChild(style);
      // window.print();
      
      // // window.print();
      // self.close();

      var deviceService = {
        isDesktop:false,
        isMobile:false
      }
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        deviceService.isDesktop = false;
        deviceService.isMobile = true;
      }else{
        deviceService.isDesktop = true;
        deviceService.isMobile = false;
      }
      var printContents = document.getElementById(divId).innerHTML;
      const stylesHtml = this.getTagsHtml('style');
      const linksHtml = this.getTagsHtml('link');
      var popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      if (deviceService.isDesktop) {
        popupWin.document.write(`
        <html>
          <head>
            <title>${title}</title>
            ${stylesHtml}
            ${linksHtml}
              <style>
              @page {
                size: ${size};
                margin: ${size == 'A4'?'0in':''}; 
                background:#ffffff;
              }
              body{
                background:#ffffff;
              }
              @media print {
                .no-print {
                  display: none;
                }
              }
              </style>
          </head>
      <body onload="window.print();window.close()">${printContents}</body>
        </html>`
        );
        popupWin.document.close();
      } else { //REMOVE window.close() if the device is a mobile.
        popupWin.document.write(`
        <html>
          <head>
            <title>${title}</title>
            ${stylesHtml}
            ${linksHtml}
              <style>
              @page {
                size: ${size};
                margin: 0in; 
                background:#ffffff;
              }
              body{
                background:#ffffff;
              }
              @media print {
                .no-print {
                  display: none;
                }
              </style>
          </head>
          <body onload="window.print()">${printContents}</body>
        </html>`
        );
        popupWin.document.close();
      }
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    });

    
  }

  printUsingTwoParameter(documentName: string, para_one, para_two) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, para_one, para_two]
        }
      }]);
  }

  printUsingThreeParameter(documentName: string, para_one, para_two, para_three) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, para_one, para_two, para_three]
        }
      }]);
  }


  printUsingFourParameter(documentName: string, para_one, para_two, para_three, para_four) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, para_one, para_two, para_three, para_four]
        }
      }]);
  }

  /**
   * to print with extra parameters
   */
  printDocumentWithExtraParams(documentName: string, documentData: string, extraParams) {
    // console.log(documentData);
    this.isPrinting = true;
    const queryParams: any = {};
    queryParams.myArray = JSON.stringify(extraParams);
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData]
        }
      }], navigationExtras);
  }

  /**
   * to print with extra parameters and 2 parameters
   */
  printDocumentTwoWithExtraParams(documentName: string, documentData: string, documentData2: string, extraParams) {
    // console.log(documentData);
    this.isPrinting = true;
    const queryParams: any = {};
    queryParams.myArray = JSON.stringify(extraParams);
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData, documentData2]
        }
      }], navigationExtras);
  }

  /**
   * to print with extra parameters and 4 parameters
   */
  printDocumentFourWithExtraParams(documentName: string, documentData: string, documentData2: string, documentData3: string, documentData4: string, extraParams) {
    // console.log(documentData);
    this.isPrinting = true;
    const queryParams: any = {};
    queryParams.myArray = JSON.stringify(extraParams);
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData, documentData2, documentData3, documentData4]
        }
      }], navigationExtras);
  }

}
