<div class="main-content">
  <div class="container main-div">
    <div class="col-md-6">
      <div *ngIf="!valid_token" class="row form-div">
        <div class="alert alert-warning alert-with-icon">
          <i class="material-icons" data-notify="icon">warning</i>
          <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close"> <i
              class="material-icons">close</i></button>
          <span data-notigy="message">
            <b>Warning :</b> {{message}} </span>
        </div>

      </div>
      <div *ngIf="valid_token" class="card">
        <div class="card-header card-header-icon card-header-rose">
          <div class="card-icon">
            <i class="material-icons">lock</i>
          </div>
          <h4 class="card-title">Reset Password</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="resetFrom" (ngSubmit)="changePassword()">
            <div class="row form-div">

              <div class="col-md-10">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Enter New Password" type="password" formControlName="password" #password>
                  <mat-error *ngIf="f.password.hasError('minlength')">Must be minimum 8 characters </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Confirm Password" type="password" formControlName="password_confirmation"
                    #password_confirmation>
                  <mat-error *ngIf="f.password_confirmation.errors">Passwords must be match</mat-error>

                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col text-center">
                <button mat-raised-button type="submit" class="btn btn-primary" [disabled]="!resetFrom.valid">
                  Reset</button>
              </div>
              <mat-progress-bar *ngIf="submitted" mode="indeterminate"></mat-progress-bar>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>