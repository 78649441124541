<div class="card">
  <div class="card-body">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
        <mat-form-field appearance="outline">
          <mat-label>Select Trip</mat-label>
          <mat-select placeholder="Select Trip" [(value)]="selected_trip" (selectionChange)="onChangeTrip()">
            <mat-option *ngFor="let trip of trips" [value]="trip?.id">
              {{trip?.trip_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3 col-xl-3" *ngIf="trip_details?.length>0">
        <button mat-button class="float-right" color="primary" (click)="generateExcel()">
          <mat-icon>get_app</mat-icon>Export
        </button>
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3 col-xl-3" *ngIf="trip_details?.length>0">
        <button mat-button class="float-right" color="primary" (click)="printCheckList()">
          <mat-icon>print</mat-icon>
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered" id="checkList" matSort (matSortChange)="onSortCheckList($event)">
        <thead class="headColor text-light">
          <tr>
            <th colspan="13">
              <h5 class="font-weight-bold text-center">GOLDEN ELITE CARGO
                {{ind_trip? ind_trip?.trip_name + ' - ':''}}
                {{ind_trip?.date | date:'dd-MM-yyyy'}}
              </h5>
            </th>
            <th colspan="4">
              <h5 class="font-weight-bold text-center"> JOB NO:
                {{ind_trip? ind_trip?.job_no + ' ':''}} </h5>
            </th>
          </tr>
          <tr>
            <th>Sl</th>
            <th mat-sort-header="id">Code</th>
            <th mat-sort-header="id">Way Bill</th>
            <th mat-sort-header="receiver_name">Receiver Name</th>
            <th>action</th>
            <th *ngFor="let item of [].constructor(10); let i = index">{{i+1}}</th>
            <th class="text-right">Total</th>
            <th style="width:30%">Remark</th>
          </tr>
        </thead>
        <tbody class="bodyColor">
          <tr *ngFor="let dat of trip_details;index as i">
            <td>{{i+1}}</td>
            <td>{{dat?.receiver_code | uppercase}}</td>
            <td>{{dat?.waybill_list}}</td>
            <td class="min">{{dat?.receiver_name | uppercase}}</td>
            <td>
              <button color="primary" mat-icon-button aria-label="Example icon-button with a edit icon"
                (click)="setIndCustomer(dat)" data-toggle="modal" data-target="#customerViewmodal">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
            <td *ngFor="let item of [].constructor(10); let i = index">
              {{dat?.loaded_array[i]?dat?.loaded_array[i]:''}}</td>
            <td class="text-right">{{dat?.total_loaded_pkg}}</td>
            <td style="width:30%"></td>
          </tr>
          <tr>
            <td colspan="15" class="text-right">Total</td>
            <td class="text-right">{{total_loaded_pkg}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <mat-paginator [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons #paginatorRef>
      </mat-paginator>

      <p colspan="12" style="font-size: small;" *ngIf="ind_trip?.is_verified">{{checklist_verification}}
      </p>
    </div>
  </div>
</div>

<div class="modal fade" id="customerViewmodal" tabindex="-1" role="dialog" aria-labelledby="customerViewmodal"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content" style="margin-top: 100px;">
      <div class="modal-header bg-info d-block">
        <button type="button" class="close float-right" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-light text-center" id="customerViewmodalLabel">View
        </h5>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12">

            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Code</th>
                  <td><span> {{ind_customer_data?.receiver_code}}</span></td>
                </tr>
                <tr>
                  <th>Way Bill</th>
                  <td><span>
                      {{ind_customer_data?.waybill_list}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Reciever Name</th>
                  <td><span> {{ind_customer_data?.receiver_name | uppercase}}</span></td>
                </tr>
                <tr>
                  <th>Reciever Code</th>
                  <td><span>
                      {{ind_customer_data?.customer_code | uppercase}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Bill To</th>
                  <td><span>
                      {{ind_customer_data?.customer_name | uppercase}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Reciever Mobile</th>
                  <td><span>
                      {{ind_customer_data?.receiver_mobile}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Reciever Email</th>
                  <td><span>
                      {{ind_customer_data?.receiver_email}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Items</th>
                  <td><span>
                      {{ind_customer_data?.item_name}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Narration</th>
                  <td><span>
                      {{ind_customer_data?.narration}}</span>
                  </td>
                </tr>
              </thead>
            </table>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="headColor text-light">
                    <th *ngFor="let item of [].constructor(10); let i = index">{{i+1}}</th>
                    <th class="text-right">Total</th>

                  </tr>
                </thead>
                <tbody class="bodyColor">
                  <tr>
                    <td *ngFor="let item of [].constructor(10); let i = index">
                      {{ind_customer_data?.loaded_array[i]?ind_customer_data?.loaded_array[i]:''}}</td>

                    <td class="text-right">{{ind_customer_data?.total_loaded_pkg}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <button mat-flat-button type="button" data-dismiss="modal" class="btn-danger">CANCEL</button>
        </div> -->
        <!-- <div class="modal-footer">
          <button mat-flat-button type="button" data-dismiss="modal" class="btn-danger">CANCEL</button>
        </div> -->
      </div>
    </div>
  </div>
</div>