import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxDialogComponent } from 'app/confirm-box/confirm-box-dialog/confirm-box-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  /**
   * Confirm dialog box
   */
  openDialogConfirm(dialogTitle, dialogText) {
    return this.dialog.open(ConfirmBoxDialogComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'confirm-dialog-container',
      data: { dialogTitle: dialogTitle, dialogText: dialogText }
    });
  }
}
