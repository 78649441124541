import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthserviceService } from './authservice.service';
import { RandomGuard } from 'app/guards/random.guard';
import { AuthGuard } from 'app/guards/auth.guard';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
  ],
  providers: [
    AuthserviceService,
    AuthModule,
    RandomGuard,
    AuthGuard,
  ],
})
export class AuthModule { }
