import { Component, OnInit } from '@angular/core';
import { PrintServiceService } from 'app/services/print-service.service';

@Component({
  selector: 'app-barcode-generator',
  templateUrl: './barcode-generator.component.html',
  styleUrls: ['./barcode-generator.component.scss']
})
export class BarcodeGeneratorComponent implements OnInit {
  isPrinting: string;
  router: any;
  columns: any;

  constructor(private printservice: PrintServiceService) { }

  ngOnInit() {
    this.columns = [];
    for (var i = 1; i <= 13; i++) {
      this.columns.push(i);
    }
    var file_name = "Barcode "
    this.printservice.onDataReady(file_name,'barcode-print','landscape');
  }





}
