import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'app/_shared/confirm-dialog/confirm-dialog.component';
import { take, map } from 'rxjs/operators';
import { ConfirmBoxDialogComponent } from 'app/confirm-box/confirm-box-dialog/confirm-box-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  constructor(private dialog: MatDialog) { }
  openDialog() {
    return this.dialog.open(ConfirmBoxDialogComponent, {
      width: '500px',
      disableClose: true,
      panelClass: 'confirm-dialog-container'
    });
  }

  public open(options) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText
      }
    });
  }
  public confirmed(): Observable<any> {

    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
    ));
  }
}
