import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VatInvoiceService {
  private apiUrl = '/vat_invoices'; 

  constructor(private http: HttpClient) { }

  getAllVatInvoices(company_id) {
    return this.http.get<any[]>(`${environment.rootUrl}${this.apiUrl}`+'/all/'+company_id);
  }
  getStatements(company,from,to) {
    return this.http.get<any[]>(`${environment.rootUrl}${this.apiUrl}`+'/statements/'+company+'/'+from+'/'+to);
  }

  getVatInvoiceDetails(id) {
    return this.http.get<any[]>(`${environment.rootUrl}${this.apiUrl}`+'/show/'+id);
  }

  getNextInvoiceId(prefix) {
    return this.http.get<any>(`${environment.rootUrl}${this.apiUrl}`+'/invoice_no?prefix='+prefix);
  }
   
  addVatInvoice(data) {
    return this.http.post<any>(`${environment.rootUrl}${this.apiUrl}`+'/store',data);
  }

  updateVatInvoice(id,data) {
    return this.http.put<any>(`${environment.rootUrl}${this.apiUrl}`+`/update/${id}`,data);
  }

  checkTrip(ref_no) {
    return this.http.get<any>(`${environment.rootUrl}${this.apiUrl}`+'/check_trip/'+`${ref_no}`);
  }
  
  getTrips(customer_id,segment) {
    return this.http.get<any>(`${environment.rootUrl}${this.apiUrl}`+'/trips?'+`customer_id=${customer_id}&segment=${segment}`);
  }
  
  getDateByInvoiceNo(prefix,invoice_no) {
    return this.http.get<any>(`${environment.rootUrl}${this.apiUrl}`+'/invoice_no/'+prefix+`?invoice_no=${invoice_no}`);
  }

}
