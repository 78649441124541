import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { WaybillService } from 'app/services/waybill.service';

@Component({
  selector: 'app-waybill-print',
  templateUrl: './waybill-print.component.html',
  styleUrls: ['./waybill-print.component.scss']
})
export class WaybillPrintComponent implements OnInit {

  waybill_no: any;
  way_bill: any;
  commodities: any;

  constructor(private waybillService: WaybillService, route: ActivatedRoute, private printservice: PrintServiceService, private cdref: ChangeDetectorRef) {
    this.waybill_no = route.snapshot.params['waybill_no'];
    // console.log(this.waybill_no);
  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  ngOnInit(): void {

    this.getIndividual(this.waybill_no);
  }
  getIndividual(id) {
    // console.log(id);
    this.waybillService.getIndWayBill(id).subscribe((res: any) => {
      // console.log(res);
      this.way_bill = res.data;
      this.commodities = res.consignment;

      var file_name = "Waybill " + this.waybill_no
      this.printservice.onDataReady(file_name,'waybill-print','portrait');
    });
  }

}
