<div class="main-content body" id="waybills-print">
    <div class="container-fluid">

        <div class="row">
            <div class="col-6 text-center">
                <h4><strong>DATE {{from | date:'dd-MM-yyyy'}}</strong></h4>
            </div>
            <div class="col-6">
                <h4><strong>DAILY RECEIVED ITEMS</strong></h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-bordered ml-3 mr-1" style="line-height: 90%;">
                    <thead>
                        <tr>
                            <th class="text-center" style="width: 7%;">DATE</th>
                            <th>CONS#</th>
                            <th>SHIPPER</th>
                            <th>CONSIGNEE</th>
                            <th>COMMODITY</th>
                            <th class="text-center" style="width: 5%;">PKG</th>
                            <th>REMARKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of way_bills">
                            <td class="nowrape">{{element?.waybill_date | date:'dd-MM-yyyy'}}</td>
                            <td>{{element?.id+'/'}}{{element?.receiver_code?element?.receiver_code:''}}</td>
                            <td>{{element?.sender_name}}</td>
                            <td>{{element?.receiver_name}}</td>
                            <td>{{element?.item_name}}</td>
                            <td>{{element?.quantity}}</td>
                            <td>{{element?.narration}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>