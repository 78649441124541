<div class="modal-content font-calibri" id="printSection"  style="background-color: #ffffff;">

    <!-- <div class="modal-header bg-info d-block">
        <button type="button" class="close float-right" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-light text-center" id="invoicemodal">Invoice
        </h5>
    </div> -->

    <div class="modal-body page">

        <div class="row" style="height: 5cm;">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                <!-- <img class="image" src="../../../assets/img/invoice-1.jpg"> -->
            </div>

            <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                <!-- <h6 class="invhead"><b>INVOICE
                        فاتورة <br>TNR: 220003481700002</b></h6> -->
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4">
                <!-- <h6>P.O Box 1869, Manama<br>Kingdom of Bahrain <br>Tel.+97317483473<br>Mob
                    +97335302143<br>goldenelitebah@gmail.com</h6> -->
            </div>
        </div>
        
    <div class="font-calibri" style="min-height: 18cm;">

        <div class="card cardborder">
            <div class="card-body row bold-12">
                <div class="col-md-8 col-sm-8 col-lg-8">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div style="width: 30%;float: left;">Vendor Name<span style="float:right">:&nbsp;&nbsp;</span></div>
                        <div style="white-space: nowrap;">
                                M/s {{head_data?.name}}</div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div style="width: 30%;float: left;">Vendor TRN<span style="float:right">:&nbsp;&nbsp;</span></div>
                        <div>{{head_data?.trn?head_data?.trn:'-'}}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-lg-4">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div style="width: 50%;float: left;">Invoice No<span style="float:right">:&nbsp;&nbsp;</span></div>
                        <div>{{head_data?.prefix ? head_data?.prefix : ''}}{{head_data?.invoice_no.toString().padStart(4, "0")}}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div style="width: 50%;float: left;">Invoice Date<span style="float:right">:&nbsp;&nbsp;</span></div>
                        <div> {{head_data?.date |
                                date:"dd-MM-yyyy"}}</div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div style="width: 50%;float: left;">TRN<span style="float:right">:&nbsp;&nbsp;</span></div>
                        <div> 
                            {{head_data?.company_trn?head_data?.company_trn:'-'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-9 col-md-9 col-lg-9 ">
                <div class="panel-body ">
                <table class="table table-bordered table-sm">
                    <thead style="background-color: lightgrey;">
                        <tr class="bold-12 font-calibri">
                            <th class="wrap w-10">Sl No</th>
                            <th class="w-50">Item</th>
                            <th class="w-10">Gross Amount</th>
                            <th class="wrap w-10">Vat %</th>
                            <th class="wrap w-10">Vat Amount</th>
                            <th class="wrap w-10">Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let invoice of individual_purchase;index as i">
                        <tr class="normal-12 font-calibri" *ngIf="invoice != undefined">
                            <td>{{i+1}}</td>
                            <td>{{invoice?.item_name}}</td>
                            <td>{{invoice?.gross_amount | number : '1.3-3'}}</td>
                            <td style="text-align: right;">
                                {{invoice?.vat_rate+'%'}}</td>
                            <td style="text-align: right;">{{(invoice?.vat_amount) | number : '1.3-3'}}</td>
                            <td style="text-align: right;">
                                {{invoice?.total | number : '1.3-3'}}</td>
                        </tr>
                        <tr class="normal-12 font-calibri" *ngIf="invoice == undefined">
                            <td style="color: #fff;">-</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>

                        </tr>
                        </ng-container>
                        <tr class="bold-12 font-calibri">
                            <td class="nowrap" colspan="2">
                                TOTAL
                            </td>
                            <td class="h6" style="text-align: right;">{{total_gross | number : '1.3-3'}}
                            </td>
                            <td></td>
                            <td class="h6" style="text-align: right;">{{(total_vat) | number : '1.3-3'}}
                            </td>
                            <td class="h6" style="text-align: right;">{{(total_tax) | number : '1.3-3'}}
                            </td>
                        </tr>
                        <tr class="bold-12 font-calibri">
                            <td class="text-capitalize" style="white-space: wrap;">Words:-
                            </td>
                            <td colspan="5" class="text-capitalize">{{words_amount}}</td>

                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <span class="normal-10 font-calibri">
                    E & O.E
                    <p >1- Cheque should be drawn in the name of <span class="bold-10">GEL CARGO GENERAL TRADING WLL, <br>IBAN No: BH37ALSA00157885100100</span> A/C Payee only.
                        <br>
                        2- Normal credit terms allow maximum period of 7 days from the date of invoice
                        <br>
                        3- The invoice amount would be considered as correct unless intimated in writing with in 7 days, from the date of invoice.
                        <br>
                        4- Official receipt will be issued upon payment <br><br>
                        </p>
                        <p class="text-center"> Thank you for choosing our
                            services
                            and looking
                            forward to pleasure of seeing you in future</p>
                    <p class="bold-10 text-center">البحرين <br>B A H R A I N <br>
                    <!-- <b>
                        <img style="width: 20%;"
                                src="../../../assets/img/bahrain2030.jpg">
                    </b> -->
                    </p>
                </span>
            </div>
        </div>

        <div class="card">
            <div class="card-body font-calibri bold-12">

                <div class="row mt-3">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        Customer Signature :
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-4">
                        Approved by :
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-4">
                        {{head_data?.company?head_data?.company:'-'}}
                    </div>
                </div>

            </div>
        </div>

    </div>
        <!-- <div class="row">
            <div class="col-sm-2 col-md-2 col-lg-2">

            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <img src="../../../assets/img/invoice-view-image-3.jpeg"
                    style="width: 100px; border-radius: 10px;">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <img src="../../../assets/img/invoice-view-image-2.jpeg"
                    style="width: 100px; border-radius: 10px;">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <img src="../../../assets/img/invoice-view-image-1.jpeg"
                    style="width: 100px; border-radius: 10px;">
            </div>
            <div class="col-sm-1 col-md-1 col-lg-1">

            </div>
        </div> -->

        <div class="row" style="height: 5cm;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <!-- <h6 class="paragraph mt-5"> Air Cargo : Sea Cargo : Land Transport : Cargo Clearing : Import &
                    Export
                </h6>
                <h6 class="paragraph"> <b>Bahrain : Dubai : China : Qatar : Saudi Arabia : India</b></h6>
                <h5 class="paragraph"><b>IMPORTING FROM ALL OVER THE WORLD</b></h5>
                <h6 class="paragraph">Tel no:00558419383, 0553429393 Al Qusais Ind Area 1, Khan Saheb Warehouse
                    # 1A,Dubai-UAE</h6>
                <h6 class="paragraph">E-mail:goldenelitedubai@gmail.com , Web: www.goldenelitegargo.com</h6> -->
            </div>
        </div>
    </div>
</div>