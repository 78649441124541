<div class="main-content">
    <div class="container-fluid">
        <div class="card mt-5">
            <div class="card-body">

                <form [formGroup]="waybillForm">
                    <div class="row mt-3 justify-content-center">

                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

                            <mat-form-field appearance="outline" style="padding-right: 15px;">
                                <mat-label>Width</mat-label>
                                <input matInput type="number" placeholder="Width" formControlName="measurement_w"
                                    #measurement_w>
                                <span matSuffix>CM</span>
                            </mat-form-field>

                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

                            <mat-form-field appearance="outline" style="padding-right: 15px;">
                                <mat-label>Height</mat-label>
                                <input matInput type="number" placeholder="Height" formControlName="measurement_h"
                                    #measurement_h>
                                <span matSuffix>CM</span>
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        </div>
                    </div>

                    <div class="row">

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field appearance="outline" style="padding-right: 15px;">
                                <mat-label>Length</mat-label>
                                <input matInput type="number" placeholder="Length" formControlName="measurement_l"
                                    #measurement_l>
                                <span matSuffix>CM</span>
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field appearance="outline">
                                <mat-label>PKG</mat-label>
                                <input matInput type="number" placeholder="PKG" formControlName="weight" #weight>
                                <span matSuffix>PKG</span>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="text-align: center;">
                            <button type="button" class="btn btn-success" [disabled]="add_disable"
                                (click)="OnSubmit()">Calculate</button>
                        </div>
                    </div>

                </form>

                <div class="row" *ngIf="total">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center;">
                        <p class="mt-3"><b>{{sub_total}} CBM * {{waybillForm.value.weight}} = {{total}}
                                CBM</b></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="total">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 tablehead">
                        <table class="table1">
                            <thead>
                                <tr>
                                    <th>Rate/CM:-</th>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <input matInput type="number" [(ngModel)]="rate" required>
                                        </mat-form-field>
                                    </td>
                                </tr>

                                <tr>
                                    <th>Total:-</th>
                                    <td><span class="tabledata">{{total}} CBM</span></td>
                                </tr>

                                <tr>
                                    <th>Amount :-</th>
                                    <td><span class="tabledata">{{total * rate }} BHD</span>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>