<div class="main-content">
    <div class="row" style="margin-top: -20px;">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="card card-lg">
                <div class="card-header" style="margin-right: 28px; margin-left: 20px;">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 ">
                        </div>
                        <div class="col-12 col-sm-12 col-md-4">
                            <h4 class="card-title  text-center margin firstheading">CREATE WAY BILL
                            </h4>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4">
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12 col-sm-12 col-md-4 " style="margin-top: -25px;">
                            <h4 class="card-title argin heading" style=" border-radius: 6px;">WAY BILL NUMBER
                            </h4>
                            <p>
                                <mat-form-field appearance="outline" class="background" style="margin-bottom: 0px;">
                                    <input matInput type="number" min="1" placeholder="Way bill Number"
                                        [(ngModel)]="waybill_no" required>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 ">
                            <p class="firstparagraph background" style="margin-bottom: 25px;">Last Generated Way Bill
                                No:&nbsp;{{last_waybill}}</p>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 " style="margin-top: -20px;">
                            <h4 class="card-title  heading" style=" border-radius: 6px;">WAY BILL DATE
                            </h4>
                            <mat-form-field appearance="outline" class="background" color="accent"
                                style="margin-right: 70px;" style="font-weight: bold;">
                                <input matInput [matDatepicker]="picker2" placeholder="Way bill date"
                                    [(ngModel)]="waybill_date">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="margin-right: 28px; margin-left: 20px; margin-top: -40px;">
                    <form [formGroup]="addWayBillForm">
                        <div class="container">

                            <div class="row text-light c-head heading">
                                <div class=" col-sm-4 col-md-4 col-lg-4">
                                    <h4 style="white-space: nowrape;">SENDER DETAILS</h4>
                                </div>
                                <div class=" col-sm-4 col-md-4 col-lg-4">
                                    <h4 style="white-space: nowrap;">RECEIVER DETAILS</h4>
                                </div>

                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <h4 style="white-space: nowrap; margin-right: 35px;">ADD RECEIVER</h4>
                                </div>
                            </div>

                            <div class="row justify-content-center">

                                <div class=" col-sm-12 col-md-4 col-lg-4 pl-3">

                                    <mat-form-field appearance="outline" class="background">
                                        <!-- <mat-label>Enter Sender Details</mat-label> -->
                                        <input matInput type="text" placeholder="Sender Details"
                                            (keyup)="searchSenders($event)" [matAutocomplete]="auto"
                                            formControlName="sender_id" #sender_id>
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)="getSenderDetails($event.option.value)">
                                            <mat-option *ngFor="let sender of sender_list" [value]="sender">
                                                {{sender?.customer_name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 pl-3">

                                    <mat-form-field appearance="outline" class="background">
                                        <!-- <mat-label>Enter Receiver Details</mat-label> -->
                                        <input matInput type="text" placeholder="Receiver Details"
                                            (keyup)="searchReceivers($event)" [matAutocomplete]="auto1"
                                            formControlName="receiver_id" #receiver_id>
                                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn1"
                                            (optionSelected)="getReceiverDetails($event.option.value)">
                                            <mat-option *ngFor="let receiver of receiver_list" [value]="receiver">
                                                {{receiver?.customer_name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div class=" col-12 col-sm-12 col-md-4 col-lg-4 pl-3">

                                    <button mat-stroked-button type="button" class="btn-success"
                                        style="height: 33px; margin-top: 4px;" tabindex="-1"
                                        title="{{ saveCommandTitleR }}" (click)="addReceiver()"
                                        aria-label="Example icon-button with a add icon">
                                        Add Receiver ({{saveCommandTitleR}})
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="show_receiver">
                            <div class="col-3" style="padding-left: 25px;">
                                {{receiver_details.customer_name}}
                            </div>
                            <div class="col-3" style="text-align: center;">
                                {{receiver_details.customer_code}}{{receiver_details.customer_code_id}}
                            </div>
                            <div class="col-3" style="text-align: center; white-space: nowrap;">
                                {{receiver_details.customer_mobile}}
                            </div>
                            <div class="col-3" style="text-align: center;">
                                {{receiver_details.country_name}}
                            </div>
                        </div>

                        <!-- start -->
                        <form [formGroup]="customerForm" *ngIf="add_receiver" (ngSubmit)="onAddCustomer()">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <mat-form-field appearance="outline" class="padding2 background">
                                        <mat-label>Enter Customer Name</mat-label>
                                        <input matInput placeholder="Name" formControlName="customer_name"
                                            #customer_name (change)="customerCodeGeneration()">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <mat-form-field appearance="outline" class="background">
                                        <mat-label>Customer code</mat-label>
                                        <input matInput placeholder="Code" formControlName="customer_code"
                                            #customer_code>
                                        <div matSuffix>{{code_id}}</div>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <mat-form-field class="example-container" appearance="outline" class=" background">
                                        <mat-label>Enter Customer Mobile</mat-label>
                                        <input matInput type="number" placeholder="Enter Mobile Number"
                                            formControlName="customer_mobile" #customer_mobile />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <mat-form-field appearance="outline" class=" background">
                                        <mat-label>Select Country</mat-label>
                                        <mat-select placeholder="Select Country" formControlName="country_id"
                                            #country_id>
                                            <mat-option *ngFor="let country of countries" [value]="country?.id">
                                                {{country?.country_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                                    <mat-form-field class="background" appearance="outline">
                                        <mat-label>Enter Customer Address</mat-label>
                                        <textarea matInput placeholder="Address" formControlName="customer_address"
                                            #customer_address></textarea>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-3" style="margin-top: 10px;">
                                    <mat-form-field class="example-container" appearance="outline" class=" background">
                                        <mat-label>Enter E-mail</mat-label>
                                        <input matInput type="email" placeholder="Enter E-mail"
                                            formControlName="customer_email" #customer_email />
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-4 col-lg-4"
                                    style="padding-left: 40px; margin-top: 20px;">
                                    <button mat-flat-button [disabled]="add_disable"
                                        style="height: 33px; margin-top: -4px;" type="submit" class="btn-success"
                                        title="{{ saveCommandTitleS }}">SUBMIT
                                        ({{saveCommandTitleS}})</button>
                                </div>
                            </div>
                        </form>
                        <!-- end -->

                        <div class="container-fluid" formArrayName="commodities">
                            <div class="row text-light c-head ridge_border">
                                <div class=" col-2 col-sm-2 col-md-2 col-lg-2">
                                    <h4 style="white-space: nowrap; font-size: 15px;">Sl</h4>
                                </div>
                                <div class=" col-3 col-sm-3 col-md-3 col-lg-3">
                                    <h4 style="white-space: nowrap; font-size: 15px;">Commodity</h4>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                    <h4 style="white-space: nowrap; font-size: 15px; padding-left: 20px;">Package</h4>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                    <h4 style="white-space: nowrap; font-size: 15px; padding-left: 20px">Origin</h4>
                                </div>
                            </div>

                            <div class="row" *ngFor="let one of commoditiesArray.controls; index as i;last as last"
                                [formGroupName]="i">
                                <div class="col-1" style="padding-left: 10px; margin-top: 20px;">
                                    {{i+1}}
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <mat-form-field appearance="outline" class="background">
                                        <!-- <mat-label>Enter Commodity</mat-label> -->
                                        <input matInput type="text" placeholder="Commodity" (keyup)="searchItem($event)"
                                            [matAutocomplete]="auto2" formControlName="item" #item>
                                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn2"
                                            (optionSelected)="getCommodityDetails($event.option.value,i)">
                                            <mat-option *ngFor="let item of item_list" [value]="item">
                                                {{item?.item_name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <mat-form-field appearance="outline" class="background">
                                        <!-- <mat-label>Enter No of Packages</mat-label> -->
                                        <input matInput type="number" min="1" placeholder="No of Packages"
                                            formControlName="item_nos" #item_nos>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                    <mat-form-field appearance="outline" class="background">
                                        <!-- <mat-label>Enter Country of Origin </mat-label> -->
                                        <input matInput type="text" placeholder="Origin Country"
                                            (keyup)="searchItemCountry($event)" [matAutocomplete]="auto5"
                                            formControlName="item_country" #item_country>
                                        <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFn5"
                                            (optionSelected)="getCountryDetails($event.option.value)">
                                            <mat-option *ngFor="let country of country_list" [value]="country">
                                                {{country?.country_name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <button type="button" mat-icon-button color="warn"
                                        aria-label="Example icon-button with a delete icon"
                                        title="{{ saveCommandTitleB }}" (click)="onRemoveRow(i)" *ngIf="i!=0"
                                        class="same-line">
                                        <mat-icon>delete</mat-icon>
                                        ({{ saveCommandTitleB }})
                                    </button>
                                </div>

                                <div class="col-12 col-sm-12 col-md-12 col-lg-12" *ngIf="last">
                                    <button type="button" mat-icon-button color="primary"
                                        aria-label="Example icon-button with a add icon" title="{{ saveCommandTitleC }}"
                                        (click)="onAddNewRow()">
                                        <mat-icon>add_circle_outline</mat-icon> Add Commodity ({{ saveCommandTitleC }})
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row text-light c-head heading mb-3">
                            <div class=" col-sm-4 col-md-4 col-lg-4">
                                <h4 style="white-space: nowrape;">PLACE OF LOADING</h4>
                            </div>
                            <div class=" col-sm-4 col-md-4 col-lg-4">
                                <h4 style="white-space: nowrap;">DESTINATION</h4>
                            </div>

                            <div class="col-sm-4 col-md-4 col-lg-4">
                                <h4 style="white-space: nowrap; margin-right: 35px;">SHIPMENT MODE</h4>
                            </div>
                        </div>

                        <div class="row" style="margin-right: 28px; margin-left: 20px;">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                <mat-form-field appearance="outline" class="background">
                                    <mat-label>Enter Loading Place</mat-label>
                                    <input matInput type="text" placeholder="Origin" (keyup)="searchOrigin($event)"
                                        [matAutocomplete]="auto3" formControlName="origin_check_id" #origin_check_id>
                                    <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn3"
                                        (optionSelected)="getOriginDetails($event.option.value)">
                                        <mat-option *ngFor="let item of origin_list" [value]="item">
                                            {{item?.check_point}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                <mat-form-field appearance="outline" class="background">
                                    <mat-label>Enter Destination</mat-label>
                                    <input matInput type="text" placeholder="Destination"
                                        (keyup)="searchDestination($event)" [matAutocomplete]="auto4"
                                        formControlName="destination_check_id" #destination_check_id>
                                    <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn4"
                                        (optionSelected)="getDestinationDetails($event.option.value)">
                                        <mat-option *ngFor="let item of destination_list" [value]="item">
                                            {{item?.check_point}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                <mat-radio-group formControlName="shipment_mode" #shipment_mode>
                                    <mat-radio-button [tabindex]="1" class="questions-radio-button" value="1">
                                        By Road
                                    </mat-radio-button>
                                    <mat-radio-button [tabindex]="2" class="questions-radio-button" value="2">
                                        By Sea
                                    </mat-radio-button>
                                    <mat-radio-button [tabindex]="3" class="questions-radio-button" value="3">
                                        By Air
                                    </mat-radio-button>
                                    <mat-radio-button [tabindex]="4" class="questions-radio-button same-line" value="4">
                                        Clearing
                                    </mat-radio-button>
                                </mat-radio-group>

                            </div>
                        </div>

                        <div class="row text-light c-head heading mb-3">
                            <div class=" col-sm-4 col-md-4 col-lg-4">
                                <h4 style="white-space: nowrape;">MEASUREMENT
                                </h4>
                            </div>
                            <div class=" col-sm-4 col-md-4 col-lg-4">
                                <h4 style="white-space: nowrap;"></h4>
                            </div>

                            <div class="col-sm-4 col-md-4 col-lg-4">
                                <h4 style="white-space: nowrap; margin-right: 35px;">WEIGHT</h4>
                            </div>
                        </div>

                        <div class="row mt-3 justify-content-center" style="margin-right: 28px; margin-left: 20px;">
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <mat-form-field class="mr-2 background" appearance="outline">
                                    <mat-label>Enter Length</mat-label>
                                    <input matInput type="number" min="0" placeholder="Length"
                                        formControlName="measurement_l" #measurement_l>
                                    <span matSuffix>CM</span>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <mat-form-field class="mr-2 background" appearance="outline">
                                    <mat-label>Enter Width</mat-label>
                                    <input matInput type="number" min="0" placeholder="Width"
                                        formControlName="measurement_w" #measurement_w>
                                    <span matSuffix>CM</span>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <mat-form-field class="mr-2 background" appearance="outline">
                                    <mat-label>Enter Height</mat-label>
                                    <input matInput type="number" min="0" placeholder="Height"
                                        formControlName="measurement_h" #measurement_h>
                                    <span matSuffix>CM</span>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <mat-form-field appearance="outline" class="background">
                                    <mat-label>Enter Weight</mat-label>
                                    <input matInput type="number" min="0" placeholder="Weight" formControlName="weight"
                                        #weight>
                                    <span matSuffix>KG</span>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style="margin-top: 30px;">
                                <h4 class="card-title margin heading narration">NARRATIONS :
                                </h4>
                            </div>
                            <div class="col-1">

                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                <mat-form-field appearance="outline">
                                    <textarea matInput placeholder="Enter Narration"
                                        formControlName="narration"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-success" style="height: 35px;" [disabled]="submitted"
                                (click)="createWayBill()">Submit
                                ({{saveCommandTitle}})</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="showWayBill" tabindex="-1" role="dialog" aria-labelledby="showWayBillLabel"
            aria-hidden="true" data-backdrop="false">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info d-block">
                        <button type="button" class="close float-right" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5>
                            <span class="modal-title text-light text-left">Date:
                                {{way_bill?.waybill_date | date:'dd-MM-yyyy'}}</span>
                            <span class="modal-title text-light float-right">No: {{way_bill?.waybill_no}}</span>
                        </h5>
                    </div>

                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 border">
                                    <h4 class="text-center"><strong> SENDER </strong></h4>
                                    <h5 class="nowrape" style="margin-left: 10px;"> Name : {{way_bill?.sender_name}}
                                    </h5>
                                    <h5 class="nowrape" style="margin-left: 10px;"> Address :
                                        {{way_bill?.sender_address}}</h5>
                                    <h5 class="nowrape" style="margin-left: 10px;"> Mobile : {{way_bill?.sender_mobile}}
                                    </h5>
                                    <h5 class="nowrape" style="margin-left: 10px;"> Email : {{way_bill?.sender_email}}
                                    </h5>
                                    <h5 class="nowrape" style="margin-left: 10px;"> Country :
                                        {{way_bill?.sender_country_name}}</h5>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 border">
                                    <h4 class="text-center"><strong> RECEIVER </strong></h4>
                                    <h5 style="margin-left: 10px;"> Name : {{way_bill?.receiver_name}}</h5>
                                    <h5 style="margin-left: 10px;"> Address : {{way_bill?.receiver_address}}</h5>
                                    <h5 style="margin-left: 10px;"> Mobile : {{way_bill?.receiver_mobile}}</h5>
                                    <h5 style="margin-left: 10px;"> Email : {{way_bill?.receiver_email}}</h5>
                                    <h5 style="margin-left: 10px;"> Country : {{way_bill?.receiver_country_name}}</h5>
                                </div>
                            </div>
                        </div>

                        <div class="container new">
                            <div class="row headclr justify-content-center text-light pt-3 mt-2">
                                <h4 style="color: black;">Description of contents</h4>
                            </div>
                            <div class="row c-head ridge_border text-light pt-3 mt-1">
                                <div class=" col-3 col-sm-3 col-md-3 col-lg-3">
                                    <h4 style="white-space: nowrap; padding-left: 15px;"><strong>No</strong></h4>
                                </div>
                                <div class=" col-5 col-sm-5 col-md-5 col-lg-5">
                                    <h4 style="white-space: nowrap;"><strong>Commodities</strong></h4>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                                    <h4 style="white-space: nowrap;"><strong>Package</strong></h4>
                                </div>
                            </div>
                            <div class="row c-body ridge_border pt-1" *ngFor="let com of commodities;index as i">
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                    {{i+1}}
                                </div>
                                <div class="col-5 col-sm-5 col-md-5 col-lg-5 ">
                                    {{com?.item_name}}
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                                    {{com?.quantity}} {{com.item_unit}}
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 text-center">
                                    <h5>
                                        <strong>{{way_bill?.origin}}</strong> =>
                                        <strong>{{way_bill?.destination}}</strong>
                                    </h5>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-12 col-md-6 ">
                                    <h5>Shipment mode:
                                        {{way_bill?.shipment_mode==1 ? 'BY ROAD': way_bill?.shipment_mode==2 ? 'BY
                                        SEA': way_bill?.shipment_mode==3 ? 'BY AIR' : 'Clearing'}}
                                    </h5>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class=" col-4 col-sm-4 col-md-4 col-lg-4">
                                    <h5 style="white-space: nowrap; padding-left:5px;">Measurement</h5>
                                </div>
                                <div class=" col-4 col-sm-4 col-md-4 col-lg-4" style="white-space: nowrap;">
                                    Length: {{way_bill?.measurement_l}} CM
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="white-space: nowrap;">
                                    Width: {{way_bill?.measurement_w}} CM
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="white-space: nowrap;">
                                    Height: {{way_bill?.measurement_h}} CM
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="white-space: nowrap;">
                                    Weight: {{way_bill?.weight}} KG
                                </div>

                            </div>
                            <div class="row mt-3">
                                <div class="col-3">
                                    <h5 style="white-space: nowrap; padding-left:10px">Volume</h5>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 ">
                                    Length X Width X Height =
                                    {{way_bill?.measurement_l*way_bill?.measurement_w*way_bill?.measurement_h}}
                                    CM<sup>3</sup>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 ">
                                    <h5 style="padding-left: 10px;">Narration: {{way_bill?.narration}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button mat-flat-button type="button" data-dismiss="modal" class="btn-danger">CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>