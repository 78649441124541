import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { WaybillService } from 'app/services/waybill.service';

@Component({
  selector: 'app-waybills-print',
  templateUrl: './waybills-print.component.html',
  styleUrls: ['./waybills-print.component.scss']
})
export class WaybillsPrintComponent implements OnInit {

  from: any;
  to: any;
  way_bills: any;
  datasource: any;
  sort: any;

  constructor(private waybillService: WaybillService, route: ActivatedRoute, private printservice: PrintServiceService) { 
    this.from = route.snapshot.params['from_date'];
    this.to = route.snapshot.params['to_date'];
    // console.log(this.from);
  }

  ngOnInit(): void {
    this.waybillService.getWayBillsBetween(this.from, this.to).subscribe((res: any) => {
      // console.log(res);
      this.way_bills = res.data;
      // this.datasource = new MatTableDataSource(res.data);
      // this.datasource.sort = this.sort;
      var file_name = "Waybill " + this.from + " to " + this.to;
      this.printservice.onDataReady(file_name,'waybills-print','landscape');
    });
  }

}
